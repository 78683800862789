import React from 'react';
import { Button, List, message } from 'antd';
import { isSP } from '../../../shared/util';
import Avatar from '../../Common/Avatar';
import styled from 'styled-components';
import { button } from '../../../color';
import { openOrDownloadAttachment } from '../../../util';
import { CommentAttachment } from 'lib';

type Props = {
  attachments: CommentAttachment[];
  onRemove: ((file: CommentAttachment) => void) | undefined;
};

export const Attachments = React.memo<Props>(({ attachments, onRemove }) => {
  const onClick = async (attachment: CommentAttachment) => {
    try {
      return openOrDownloadAttachment(attachment);
    } catch (e) {
      console.error(e);
      message.error('ファイルの取得に失敗しました。');
    }
  };

  const onDownload = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    attachment: CommentAttachment
  ) => {
    e.stopPropagation();
    return openOrDownloadAttachment(attachment, true);
  };

  const onClickRemove = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    attachment: CommentAttachment
  ) => {
    e.stopPropagation();
    onRemove?.(attachment);
  };

  return attachments.length ? (
    <List
      itemLayout="horizontal"
      grid={{ gutter: 4, column: isSP() ? 2 : 4 }}
      dataSource={attachments}
      renderItem={(attachment) => (
        <ListItem>
          <Attachment onClick={() => onClick(attachment)}>
            <AttachmentText>{attachment.filename}</AttachmentText>
            <IconWrapper onClick={(e) => onDownload(e, attachment)}>
              <Avatar size="small" icon="arrow-down" />
            </IconWrapper>
            {onRemove && (
              <IconWrapper onClick={(e) => onClickRemove(e, attachment)}>
                <Avatar size="small" icon="delete" />
              </IconWrapper>
            )}
          </Attachment>
        </ListItem>
      )}
    />
  ) : (
    <></>
  );
});

const ListItem = styled(List.Item)`
  margin-top: 4px;
  margin-bottom: 0 !important;
`;

const Attachment = styled(Button)`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  padding: 15px 12px;
`;

const IconWrapper = styled.div`
  margin-left: 0.5rem;

  &:hover > * {
    background: ${button.background.primary};
  }

  * {
    font-size: 12px;
  }
`;

const AttachmentText = styled.span`
  margin-right: 0.5rem;
  overflow: hidden;
  text-align: left;
  flex-grow: 1;
  text-overflow: ellipsis;
`;
