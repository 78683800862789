import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Icon, Spin } from 'antd';
import Room from './room';

class Index extends Component {
  render() {
    const { roomId } = this.props.match.params;
    const room = this.props.store.getRoom(roomId);
    if (!room)
      return (
        <SpinWrapper>
          <Spin indicator={<Icon type="loading" spin />} />
        </SpinWrapper>
      );
    return <Room room={room} key={room.id} />;
  }
}

export default compose(withRouter, inject('store'), observer)(Index);

const SpinWrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
