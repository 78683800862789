import { Route, Switch } from 'react-router-dom';
import Conversations from '../Conversations';
import DraftConversation from '../Conversations/draft';
import { ScheduledConversation } from '../Conversations/ScheduledConversation';
import SentConversation from '../Conversations/sentConversation';
import { LineConversation } from '../Line';

export const MessagePaneDetail = () => {
  return (
    <Switch>
      {/* 自分 - 下書き */}
      <Route
        path="/me/drafts/:draftId/teams/:teamId/inboxes/:inboxId"
        exact
        component={DraftConversation}
      />
      <Route path="/me/drafts/:draftId" exact component={DraftConversation} />
      {/* 自分 - 送信予約 */}
      <Route
        path="/me/scheduled/:draftId"
        exact
        component={ScheduledConversation}
      />
      {/* 自分 - 送信済み */}
      <Route path="/me/sent/:sentId" exact component={SentConversation} />
      {/* 自分 - コメント */}
      <Route
        path="/me/commented/messages/:messageId/comments/:commentId"
        exact
        component={Conversations}
      />
      {/* 自分 - メンション */}
      <Route
        path="/me/mentioned/messages/:messageId/comments/:commentId"
        exact
        component={Conversations}
      />
      {/* 自分 - 自分が担当 */}
      <Route
        path="/me/assigned/:tab/:messageId"
        exact
        component={Conversations}
      />
      <Route path="/me/assigned/sent/:sentId" component={SentConversation} />
      {/* 自分 - 自分が担当 */}
      <Route
        path="/me/starred/messages/:messageId"
        exact
        component={Conversations}
      />
      {/* 検索 */}
      <Route
        path={[
          '/search/messages/:query/:messageId',
          '/search/deleted/:query/:messageId',
        ]}
        exact
        component={Conversations}
      />
      <Route
        path="/search/sent/:query/:sentId"
        exact
        component={SentConversation}
      />

      {/* チーム - LINE */}
      <Route
        path="/teams/:teamId/lineaccounts/:lineAccountId/:tab/:lineThreadId"
        exact
        component={LineConversation}
      />

      {/* チーム */}
      {/* チーム - 送信済み */}
      <Route
        path={[
          '/teams/:teamId/sent/:sentId',
          '/teams/:teamId/inboxes/:inboxId/tags/:tagId/sent/:sentId',
          '/teams/:teamId/tags/:tagId/sent/:sentId',
        ]}
        exact
        component={SentConversation}
      />
      <Route
        path={[
          '/teams/:teamId/tags/:tagId/:tab/:messageId',
          '/teams/:teamId/inboxes/:inboxId/tags/:tagId/:tab/:messageId',
          '/teams/:teamId/:tab/:messageId',
        ]}
        exact
        component={Conversations}
      />
      {/* チーム - すべてのメール - コメントのハイライト*/}
      <Route
        path="/teams/:teamId/messages/:messageId/comments/:commentId"
        exact
        component={Conversations}
      />
      {/* チーム - ゴミ箱 */}
      <Route
        path={[
          '/teams/:teamId/deleted/:messageId',
          '/teams/:teamId/inboxes/:inboxId/tags/:tagId/deleted/:messageId',
          '/teams/:teamId/tags/:tagId/deleted/:messageId',
        ]}
        exact
        component={Conversations}
      />
    </Switch>
  );
};
