import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Conversation from '../Conversations/Conversation';
import { Draft } from './Conversation/Draft';
import styled from 'styled-components';
import * as color from '../../../color';
import media from 'styled-media-query';
import { SPBackButton } from './Conversation/SPBackButton';
import { onSnapshot } from 'firebase/firestore';
import { companyDoc, registerUnsubscribe } from '../../../firestore';
import { draftConverter } from '../../../firestore/entity/draft';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addCommentHeight: 0,
      draft: null,
    };
    this.messageDetailRef = React.createRef();
  }

  componentDidMount() {
    const { draftId } = this.props.match.params;
    this.setDraft(draftId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { draftId } = this.props.match.params;
    const prevDraftId = prevProps.match.params.draftId;
    if (draftId !== prevDraftId) {
      this.setDraft(draftId);
    }
  }

  componentWillUnmount() {
    this.unsubscribeDraft?.();
  }

  setDraft = (draftId) => {
    this.unsubscribeDraft?.();
    this.unsubscribeDraft = registerUnsubscribe(
      Index,
      onSnapshot(companyDoc('drafts', draftId, draftConverter), (snapshot) =>
        this.setState({ draft: snapshot.data() ?? null })
      )
    );
  };

  render() {
    const { draft } = this.state;
    if (!draft) return <div />;

    if (draft.isReply) {
      // 返信の場合は会話を表示
      return (
        <Conversation messageId={draft.inReplyToMessageId} key={draft.id} />
      );
    }

    // 新規作成は下書きのみを表示
    return (
      <Wrapper>
        <Header>
          <SPBackButton backLink="/me/drafts" />
        </Header>
        <Draft draft={draft} key={draft.id} />
      </Wrapper>
    );
  }
}

export default compose(withRouter)(Index);

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
  overflow: auto;
  background-color: ${color.sidebar.background.normal};

  ${media.lessThan('small')`
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 2;
  `};
`;

const Header = styled.div`
  padding: 10px;
`;
