import { Inbox } from 'lib';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks/useStore';
import { MyEmailSettings, MyEmailSettingsUpdate } from './MyEmailSettings';
import { useState } from 'react';
import { SmtpEditDialogWithLogic } from '../../inbox/SmtpEditDialog/SmtpEditDialogWithLogic';
import { ImapImportDialogWithLogic } from '../../inbox/ImapImportDialog/ImapImportDialogWithLogic';
import { useToast } from '../../../../../hooks/useToast';

type Props = {
  inbox: Inbox;
};

export const MyEmailSettingsWithLogic = observer(({ inbox }: Props) => {
  const store = useStore();
  const { showToast } = useToast();
  const [smtpDialogOpen, setSmtpDialogOpen] = useState(false);
  const [imapDialogOpen, setImapDialogOpen] = useState(false);
  const handleUpdateNames = async (update: MyEmailSettingsUpdate) => {
    await inbox.ref
      .update({
        name: update.name,
        yaritoriName: update.yaritoriName,
        isOneClickUnsubscribeEnabled: update.isOneClickUnsubscribeEnabled,
      })
      .then(() => {
        showToast('success', '更新しました');
      })
      .catch((e) => {
        console.error(e);
        showToast('error', '更新に失敗しました');
      });
  };
  const handleDelete = async () => {
    await inbox.ref.delete();
  };
  return (
    <>
      <MyEmailSettings
        forwardedEmail={inbox.forwardTo}
        importImapSupported={store.isImportImapSupported}
        smtpEnabled={inbox.smtp}
        onOpenSmtpDialog={() => setSmtpDialogOpen(true)}
        onOpenImapDialog={() => setImapDialogOpen(true)}
        settings={{
          name: inbox.name,
          yaritoriName: inbox.yaritoriName,
          isOneClickUnsubscribeEnabled: inbox.isOneClickUnsubscribeEnabled,
        }}
        onUpdateNames={handleUpdateNames}
        onDelete={handleDelete}
      />
      {smtpDialogOpen && (
        <SmtpEditDialogWithLogic
          inboxId={inbox.id}
          onClose={() => setSmtpDialogOpen(false)}
        />
      )}
      {imapDialogOpen && (
        <ImapImportDialogWithLogic
          inboxId={inbox.id}
          onClose={() => setImapDialogOpen(false)}
        />
      )}
    </>
  );
});
