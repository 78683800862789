import { estimateServerTimestampsOption } from './common';
import _ from 'lodash';
import firebase from 'firebase/compat/app';

export const chatHistoriesDelimiter = '_chatHistories_';

export interface RoomData {
  members: string[];
  isGroup: boolean;
  readers: { [userId: string]: firebase.firestore.Timestamp };
  chatHistories: { [key: string]: firebase.firestore.Timestamp };
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

export class Room {
  private _id: string;
  private _data: RoomData;
  private _ref: firebase.firestore.DocumentReference<RoomData>;
  private _unreadCount: number | undefined;

  constructor(doc: firebase.firestore.DocumentSnapshot<RoomData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for room#${doc.id}`);
    }
    this._data = data;
    this._ref = doc.ref;
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get members() {
    return this._data.members;
  }

  get isGroup() {
    return this._data.isGroup;
  }

  get readers() {
    return this._data.readers || {};
  }

  get chatHistories() {
    return this._data.chatHistories || {};
  }

  get createdAt() {
    return this._data.createdAt;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }

  unreadCount(userId: string) {
    if (this._unreadCount) return this._unreadCount;
    // ユーザの既読時間を取得
    const readTimestamp = this.readers[userId];
    // 既読時間がない場合、すべて未読
    if (!readTimestamp) {
      this._unreadCount = Object.keys(this.chatHistories).length;
      return this._unreadCount;
    }

    // 自分以外、かつ未読であるメッセージを取得する
    const unreadChatHistoriesExceptMe = _.filter(this.chatHistories, (v, k) => {
      return !k.startsWith(userId) && v.seconds > readTimestamp.seconds;
    });

    this._unreadCount = Object.keys(unreadChatHistoriesExceptMe).length;
    return this._unreadCount;
  }
}
