import { message as antdMessage, Modal, Spin } from 'antd';
import { usePrevious } from 'App/Settings/common';
import { useStore } from 'hooks/useStore';
import { Inbox } from 'lib';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { IntegrationRoutes } from '../../IntegrationRoutes';
import { chatworkAuthorizeUrl } from '../authorize';
import { ChatworkIntegrationDisabled } from './ChatworkIntegrationDisabled';
import { ChatworkIntegrationEnabled } from './ChatworkIntegrationEnabled';

const { confirm } = Modal;

type Props = {
  routes: IntegrationRoutes;
};

export const ChatworkIntegration: React.FC<Props> = observer(({ routes }) => {
  const { integrationStore } = useStore();
  const store = integrationStore.chatworkStore;
  const location = useLocation();

  const { integration, isSupported, hasPermission, isScopeMissing } = store;

  const prevIntegration = usePrevious(integration);
  const loadRooms = useCallback(() => store.loadRooms(), [store]);

  const needsReintegrate = (integration && isScopeMissing) ?? false;
  const authorizeUrl = chatworkAuthorizeUrl(routes.getCallbackUrl('chatwork'));

  // Chatwork連携
  const integrate = async (code: string) => {
    try {
      await store.integrate(code, routes.getCallbackUrl('chatwork'));
      antdMessage.success('Chatworkと連携しました');
    } catch (e) {
      antdMessage.error('Chatworkとの連携に失敗しました');
      throw e;
    }
  };

  const handleUnintegrate = async () => {
    confirm({
      title: 'Chatworkとの連携を解除します。よろしいですか？',
      content:
        '会社に紐付けられたすべてのChatworkルームへの通知が無効になります。\n ※解除後でも、再度連携が可能です。',
      okText: '解除',
      cancelText: 'キャンセル',
      onOk: async () => {
        // Chatwork連携解除
        try {
          await store.unintegrate();
          antdMessage.success('Chatworkとの連携を解除しました');
        } catch (e) {
          antdMessage.error('Chatworkとの連携解除に失敗しました');
          throw e;
        }
      },
      onCancel() {
        //
      },
      okType: 'danger',
      maskClosable: true,
    });
  };

  const handleStartIntegrate = () => {
    window.location.href = authorizeUrl;
  };

  const handleSelectRoom = async (inbox: Inbox, roomId: number | null) => {
    try {
      await store.selectRoom(inbox, roomId);
      antdMessage.error('通知先のルームを設定しました');
    } catch (e) {
      antdMessage.error('通知先のルームの設定に失敗しました');
      throw e;
    }
  };

  useEffect(() => {
    if (integration && !isScopeMissing) {
      // 既に連携中の場合
      loadRooms();
    }

    const match = routes.matchCallbackPath(
      location.pathname,
      location.search,
      'chatwork'
    );

    if (match) {
      if (match.code) {
        integrate(match.code);
      }
      routes.toIndex();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (integration && integration !== prevIntegration) {
      // 連携を取得した場合
      loadRooms();
    }
  }, [prevIntegration, integration, loadRooms]);

  return (
    <Wrapper>
      {store.isLoadingIntegrations ? (
        <Spin />
      ) : integration ? (
        <ChatworkIntegrationEnabled
          userName={integration.authedUser.name}
          noPermission={!hasPermission}
          needsReintegration={needsReintegrate}
          notSupported={!isSupported}
          isUnintegrating={store.isUnintegrating}
          onUnintegrate={handleUnintegrate}
          onReintegrate={handleStartIntegrate}
          onSelectRoom={handleSelectRoom}
          teams={store.integratableTeams}
          rooms={store.rooms}
          getTeamInboxes={(teamId: string) => store.getTeamInboxes(teamId)}
          roomsLoading={store.isLoadingRooms}
          authorizeUrl={authorizeUrl}
        />
      ) : (
        <ChatworkIntegrationDisabled
          noPermission={!hasPermission}
          notSupported={!isSupported}
          isIntegrating={store.isIntegrating}
          onIntegrate={handleStartIntegrate}
        />
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  margin-bottom: 30px;
`;
