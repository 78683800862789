import { decorateHtml, StorageMessage } from 'lib';
import { observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { fetchAsJson, fetchAsText } from '../../../../../util';
import MessageBody from './MessageBody';
import { MessageHeader } from './MessageHeader';
import { compose } from 'recompose';

export * from './MessageRecipient';

class TopConversationMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: props.collapsed,
      storageMessage: null,
      storageHtml: null,
      storageTextAsHtml: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const collapsed = this.props.collapsed;
    if (!collapsed && collapsed !== prevProps.collapsed) {
      this.setState({ collapsed });
    }
  }

  fetch = async () => {
    await this.fetchMessageFromStorage();
    setTimeout(() => {
      this.props.scrollToAnchorComment();
    }, 1000);
    await this.props.markAsRead();
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  startReply = () => this.props.startReply(this.props.message, null, false);

  startReplyAll = () => this.props.startReply(this.props.message, null, true);

  startForwarding = () =>
    this.props.startForwarding(this.state.storageMessage?.attachments);

  fetchMessageFromStorage = async () => {
    const {
      id,
      storagePath,
      textAsHtml,
      textAsHtmlStoragePath,
      html,
      htmlStoragePath,
    } = this.props.message;

    if (html) {
      this.setState({ loading: false });
      return;
    }

    if (htmlStoragePath) {
      const storageHtml = await fetchAsText(htmlStoragePath);
      this.setState({ storageHtml: decorateHtml(storageHtml), loading: false });
      return;
    }

    if (textAsHtml) {
      this.setState({ loading: false });
      return;
    }

    if (textAsHtmlStoragePath) {
      const storageTextAsHtml = await fetchAsText(textAsHtmlStoragePath);
      this.setState({
        storageTextAsHtml: decorateHtml(storageTextAsHtml),
        loading: false,
      });
      return;
    }

    if (storagePath) {
      const storageMessage = await this.fetchStorageMessage(id, storagePath);
      this.setState({ storageMessage, loading: false });
      return;
    }
    // Empty body. Fallback to textAsHtml
    this.setState({ loading: false });
  };

  fetchStorageMessage = async (id, path) => {
    return new StorageMessage(id, await fetchAsJson(path));
  };

  html() {
    const { loading, storageMessage, storageHtml, storageTextAsHtml } =
      this.state;
    if (loading) return null;
    if (storageHtml) return storageHtml;
    if (storageTextAsHtml) return storageTextAsHtml;
    if (storageMessage) {
      return storageMessage.html
        ? storageMessage.html
        : storageMessage.sanitizedTextAsHtml;
    }
    const { message } = this.props;
    const { html, textAsHtml } = message;
    if (html) return html;
    return decorateHtml(textAsHtml);
  }

  render() {
    const locker = !!this.props.lock
      ? this.props.store.getUser(this.props.lock.locker)
      : null;
    const me = this.props.store.me;

    return (
      <div
        className="my-4 w-full rounded-xl bg-white shadow-card"
        name={`message-${this.props.message.id}`}
        data-testid="conversationMessage"
      >
        <MessageHeader
          message={this.props.message}
          lock={this.props.lock}
          startReply={this.startReply}
          startReplyAll={this.startReplyAll}
          startForwarding={this.startForwarding}
          collapsed={this.state.collapsed}
          toggleCollapsed={this.toggleCollapsed}
          markMessageAsDeleted={this.props.markMessageAsDeleted}
          restoreMessage={this.props.restoreMessage}
          deleteMessage={this.props.deleteMessage}
          store={this.props.store}
        />
        {!this.state.collapsed && (
          <MessageBody
            message={this.props.message}
            lock={this.props.lock}
            startReply={this.startReply}
            startReplyAll={this.startReplyAll}
            startReplyInstead={this.props.scrollToBottom}
            startForwarding={this.startForwarding}
            html={this.html()}
            locker={locker}
            me={me}
            loading={this.state.loading}
            attachments={
              this.state.storageMessage?.attachments ||
              this.props.message.attachments
            }
            isReadOnly={me.isReadOnly}
          />
        )}
      </div>
    );
  }
}

export default compose(observer, withRouter)(TopConversationMessage);
