import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { Modal, Button } from 'antd';
import { Comment } from '../../Common/Comment';
import { H2 } from '../../Common/H2';
import Avatar from '../../Common/Avatar';
import Tooltip from '../../Common/Tooltip';
import {
  Member,
  Profile,
  NameAndEmail,
  RemoveButton,
  Name,
} from '../../Common';
class EditMemberModal extends React.Component {
  state = {
    open: false,
    otherMembers: [],
    members: [],
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open,
      });

      if (this.props.open) {
        this.initData();
      }
    }
  }

  initData() {
    const { room } = this.props;
    const { users, me } = this.props.store;

    let members = [];
    room.members.forEach((m) => {
      if (m !== me.id) {
        const member = this.props.store.getUser(m);
        if (member) {
          members.push(member);
        } else {
          members.push({ id: m, name: '削除されたユーザ' });
        }
      }
    });

    const otherMembers = users.filter(
      (u) => u.id !== me.id && !members.find((m) => m.id === u.id)
    );

    this.setState({ members, otherMembers });
  }

  close() {
    this.setState({ open: false });
    this.props.setOpen(false);
  }

  removeUser(index) {
    let members = this.state.members;
    let otherMembers = this.state.otherMembers;
    otherMembers.push(members[index]);
    members.splice(index, 1);
    this.setState({ members, otherMembers });
  }

  addUser(index) {
    let members = this.state.members;
    let otherMembers = this.state.otherMembers;
    members.push(otherMembers[index]);
    otherMembers.splice(index, 1);
    this.setState({ members, otherMembers });
  }

  async handleOk() {
    this.setState({ loading: true });
    const { room } = this.props;
    await room.ref.update({
      members: [this.props.store.me.id, ...this.state.members.map((m) => m.id)],
    });
    this.setState({ loading: false });
    this.close();
  }

  render() {
    const { loading } = this.state;
    return (
      <Modal
        title="メンバー設定"
        visible={this.state.open}
        onCancel={this.close.bind(this)}
        footer={[
          <Button key="back" onClick={this.close.bind(this)}>
            キャンセル
          </Button>,
          <Button
            loading={loading}
            disabled={loading || this.state.members.length === 0}
            key="submit"
            type="primary"
            onClick={this.handleOk.bind(this)}
          >
            更新
          </Button>,
        ]}
      >
        {this.state.members.length > 0 && <H2>追加済みのメンバー</H2>}
        {this.state.members.map((u, index) => (
          <Member key={u.id}>
            <Profile>
              <Avatar user={u} />
              <NameAndEmail>
                <Name>{u.name}</Name>
                <Comment>{u.email}</Comment>
              </NameAndEmail>
            </Profile>
            <Tooltip title="権限がありません">
              <RemoveButton
                disabled={this.state.members.length === 1}
                type="danger"
                onClick={() => this.removeUser(index)}
              >
                削除
              </RemoveButton>
            </Tooltip>
          </Member>
        ))}

        {this.state.otherMembers.length > 0 && (
          <H2 style={{ marginTop: '20px' }}>メンバーを追加する</H2>
        )}
        {this.state.otherMembers.map((u, index) => (
          <Member key={u.id}>
            <Profile>
              <Avatar user={u} />
              <NameAndEmail>
                <Name>{u.name}</Name>
                <Comment>{u.email}</Comment>
              </NameAndEmail>
            </Profile>
            <Tooltip title="権限がありません">
              <Button type="primary" onClick={() => this.addUser(index)}>
                追加
              </Button>
            </Tooltip>
          </Member>
        ))}
      </Modal>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(EditMemberModal);
