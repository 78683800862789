import { observer } from 'mobx-react';
import { useStore } from '../../hooks/useStore';
import { Route, Switch, useHistory } from 'react-router-dom';
import {
  COMPANY_EMAIL_PATHS,
  createIntegrateRoutes,
  createMyEmailRoutes,
  MY_EMAIL_PATHS,
} from './routes';
import { PageWithMainNav } from '../Common/MainNav';
import { SettingsSidebarWithLogic } from './Sidebar/SettingsSidebarWithLogic';
import { MyProfile } from './MyProfile';
import MyGeneral from './MyGeneral/MyGeneral';
import { MyEmail } from './email/MyEmail';
import { MembersAndGroups } from './MembersAndGroups';
import Teams from './Teams/Teams';
import { Payments } from './Payment/Payments';
import IPLimited from './IPLimited/IPLimited';
import { DomainAuth } from './DoaminAuth/DomainAuth';
import { YaritoriAI } from './YaritoriAI';
import { TeamsRedirect } from './TeamsRedirect';
import { TeamMembersWithLogic } from './TeamMembers/TeamMembersWithLogic';
import { LineSettings } from './Line';
import { Integrations } from './integration';
import { SettingPageLayout } from './common/SettingPageLayout/SettingPageLayout';
import { CompanyEmailWithLogic } from './email/CompnayEmail/CompanyEmailWithLogic';
import { DeliveryAddressesWithLogic } from './DeliveryAddresses/DeliveryAddressesWithLogic';

export const Settings = observer(() => {
  const store = useStore();
  const history = useHistory();
  const myEmailRoutes = createMyEmailRoutes(history);
  const integrationRoutes = createIntegrateRoutes(history);

  return (
    <PageWithMainNav>
      <SettingPageLayout sidebar={<SettingsSidebarWithLogic />}>
        <Switch>
          {/* -- 個人設定 -- */}
          {/* 自分 - プロフィール */}
          <Route exact path="/settings/me/profile" component={MyProfile} />
          {/* 自分 - 設定 */}
          <Route
            exact
            path="/settings/me/general"
            render={() => <MyGeneral integrationRoutes={integrationRoutes} />}
          />
          {/* 自分 - 個人メール設定 */}
          <Route
            path={MY_EMAIL_PATHS.base}
            render={() => <MyEmail store={store} routes={myEmailRoutes} />}
          />
          {/* -- 会社設定 -- */}
          {/* 会社 - メンバー */}
          <Route
            exact
            path={['/settings/company/members', '/settings/company/groups']}
            component={MembersAndGroups}
          />
          {/* 会社 - チーム */}
          <Route exact path="/settings/company/teams" component={Teams} />
          {/* 会社 - お支払い */}
          <Route exact path="/settings/company/payments" component={Payments} />
          {/* 会社 - IP制限 */}
          <Route
            exact
            path="/settings/company/ip-limited"
            component={IPLimited}
          />
          {/* 会社 - ドメイン認証 */}
          <Route
            exact
            path="/settings/company/domain-auth"
            component={DomainAuth}
          />
          {/* 会社 - yaritori AI */}
          <Route
            exact
            path="/settings/company/yaritori-ai"
            component={YaritoriAI}
          />
          {/* 会社 - 一斉配信メール設定 */}
          <Route
            exact
            path="/settings/company/delivery-addresses"
            component={DeliveryAddressesWithLogic}
          />
          {/* チーム - メンバー */}
          <Route exact path="/settings/teams" component={TeamsRedirect} />
          <Route
            exact
            path="/settings/teams/:teamId/members"
            component={TeamMembersWithLogic}
          />
          <Route
            exact
            path="/settings/teams/:teamId/line"
            component={LineSettings}
          />
          {/* チーム - メール */}
          <Route
            path={COMPANY_EMAIL_PATHS.base}
            component={CompanyEmailWithLogic}
          />
          {/* 連携 - 外部サービス */}
          <Route
            path={integrationRoutes.paths.index}
            render={() => <Integrations routes={integrationRoutes} />}
          />
        </Switch>
      </SettingPageLayout>
    </PageWithMainNav>
  );
});
