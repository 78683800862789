import React, { useEffect, useState } from 'react';
import Chat from './chat';

const Chats = ({ chats, scrollToBottom }) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    // MEMO: setTimeoutなしだとスクロールがされない
    setTimeout(() => {
      scrollToBottom();
      setVisible(true);
    }, 400);
  });

  return (
    <div style={{ visibility: visible ? 'visible' : 'hidden' }}>
      {chats.map((chat) => (
        <Chat chat={chat} key={chat.id} />
      ))}
    </div>
  );
};

export default Chats;
