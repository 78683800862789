import React, {
  KeyboardEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { Button, Mentions, message as antdMessage, Upload } from 'antd';
import Avatar from '../../../../Common/Avatar';
import {
  extractMentionedUsers,
  isCmdOrCtrlEnter,
  toCommentAttachment,
} from '../../../../../util';
import { CommentAttachment, Message, User } from 'lib';
import { RcFile, UploadProps } from 'antd/lib/upload/interface';
import { UploadFile } from 'antd/es/upload/interface';
import { db9, storage } from '../../../../../firebase';
import { deleteObject, ref, uploadBytesResumable } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import store from '../../../../../store';
import { doc, runTransaction, serverTimestamp } from 'firebase/firestore';
import { uid as rcuid } from '../../../CreateMessage/rcuid';
import { Attachments } from '../../../Comment/Attachments';
import TextArea from 'antd/lib/input/TextArea';
import { useDropzone } from 'react-dropzone';
import * as color from '../../../../../color';
import { Group } from '../../../../../store/entity';
import { eventNames, logEvent } from 'analytics';
import { Icon } from 'components/basics';
import { AddCommentSubmit, NoteAdd } from 'components/icons';
import { tv } from 'tailwind-variants';
import { PCOnly } from 'App/Common/MediaQuery';

const { Option } = Mentions;

const countString = (str: string, sep: string) => {
  return str.split(sep).length - 1;
};

const wrapper = tv({
  base: 'flex w-full items-center rounded',
  variants: {
    disabled: {
      true: 'cursor-not-allowed bg-[#f5f5f5]',
      false: 'bg-white',
    },
  },
});

const mentions = tv({
  base: 'border-none shadow-none outline-none',
  variants: {
    disabled: {
      true: 'pointer-events-none',
    },
  },
});

type Props = {
  // This component is used by both the Conversation and Chat.
  message?: Message;
  addComment(comment: string): void;
  me: User;
  teamMembers: User[];
  users: User[];
  groups: Group[];
  placeholder: string;
  useMention?: boolean;
  disabled?: boolean;
};

export const AddComment: React.FC<Props> = ({
  message,
  me,
  addComment,
  placeholder,
  teamMembers,
  useMention,
  users,
  groups,
  disabled = false,
}) => {
  const [commentId, setCommentId] = useState(
    () => doc(store.collection('comments')).id
  );
  const [adding, setAdding] = useState(false);
  const [comment, setComment] = useState('');
  const [rows, setRows] = useState(1);
  const [attachments, setAttachments] = useState<UploadFile[]>([]);
  const [uploadingAttachments, setUploadingAttachments] = useState<RcFile[]>(
    []
  );
  const [removingAttachments, setRemovingAttachments] = useState<
    CommentAttachment[]
  >([]);

  const mentionRef = useRef<any>(null);
  const uploadRef = useRef<HTMLDivElement>(null);
  const unmountRef = useRef(false);

  const onChange = (value: string) => {
    setComment(value);
    const rows = countString(value, '\n') + 1;
    setRows(rows > 5 ? 5 : rows);
  };

  const showUploadDialog = () => uploadRef.current?.click();

  useEffect(
    () => () => {
      unmountRef.current = true;
    },
    []
  );

  useEffect(() => {
    return () => {
      if (unmountRef.current) {
        setAttachments([]);
        unmountRef.current = false;
        Promise.all(
          attachments
            .map((f) => ref(storage, f.fileName))
            .map((r) =>
              deleteObject(r).catch((e) => {
                if (e.code !== 'storage/object-not-found') {
                  throw e;
                }
              })
            )
        );
      }
    };
  }, [attachments]);

  const onDrop = async (files: File[]) => {
    for (const file of files) {
      const rcFile = file as RcFile;
      rcFile.uid = rcuid();
      await validateAndUpload(rcFile);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: disabled || !useMention,
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  const isFileSizeExceeded = useCallback(
    (uploadSize: number) => {
      const totalSize = attachments.reduce((a, b) => a + b.size, 0);
      return !((uploadSize + totalSize) / 1024 / 1024 < 15);
    },
    [attachments]
  );

  const uploadFileToStorage = useCallback(
    async (file: RcFile) => {
      return new Promise((resolve, reject) => {
        if (!message) {
          reject();
          return;
        }
        setUploadingAttachments((prev) => [...prev, file]);
        const attachmentId = uuidv4();
        const fileRef = ref(
          storage,
          `companies/${store.signInCompany}/comments/${commentId}/attachments/${attachmentId}/${file.name}`
        );

        const uploadTask = uploadBytesResumable(fileRef, file, {
          customMetadata: {
            teamId: message.teamId,
            uploader: me.id,
          },
        });

        antdMessage.loading({
          content: `${file.name}をアップロード中です（0%完了）`,
          key: attachmentId,
        });

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            antdMessage.loading({
              content: `${file.name}をアップロード中です（${Math.floor(
                progress
              )}%完了）`,
              key: attachmentId,
            });
          },
          (error) => {
            console.error('CreateMessage.uploadFileToStorage:', error);
            antdMessage.error({
              content: `${file.name}のアップロードに失敗しました`,
              key: attachmentId,
            });
            setUploadingAttachments((prev) =>
              prev.filter((f) => f.uid !== file.uid)
            );
            reject();
          },
          async () => {
            // 成功
            const uploadFile = file as UploadFile;
            uploadFile.fileName = fileRef.fullPath;
            setAttachments((prev) => [...prev, uploadFile]);
            setUploadingAttachments((prev) =>
              prev.filter((f) => f.uid !== file.uid)
            );
            antdMessage.success({
              content: `${file.name}のアップロードが完了しました。`,
              key: attachmentId,
              duration: 2,
            });
            resolve(uploadFile);
          }
        );
      });
    },
    [message, commentId, me.id]
  );

  const validateAndUpload = useCallback(
    async (file: RcFile) => {
      if (isFileSizeExceeded(file.size)) {
        antdMessage.error('添付ファイルの合計は15MBまでです');
      } else {
        await uploadFileToStorage(file);
      }
    },
    [isFileSizeExceeded, uploadFileToStorage]
  );

  const beforeUpload = (file: RcFile) => {
    // noinspection JSIgnoredPromiseFromCall
    validateAndUpload(file);
    return false;
  };

  const onRemove = useCallback(
    async (attachment: CommentAttachment) => {
      const file = attachments.find(
        (f) => f.fileName === attachment.storagePath
      );
      if (!file) {
        return;
      }
      setRemovingAttachments((prev) => [...prev, attachment]);
      await deleteObject(ref(storage, attachment.storagePath));
      setAttachments((prev) => prev.filter((f) => f.uid !== file.uid));
      setRemovingAttachments((prev) =>
        prev.filter((f) => f.storagePath !== attachment.storagePath)
      );
    },
    [attachments]
  );

  const addCommentToMessage = async () => {
    if (!message) {
      return;
    }
    const mentionedUsers = extractMentionedUsers(
      comment,
      store.users,
      store.groupStore.groups,
      store.getUsersByTeamId(message.teamId)
    );
    const messageRef = store.doc('messages', message.id);

    // コメントを作成する
    const commentData = {
      teamId: message.teamId,
      commenter: store.me.id,
      messageId: message.id,
      // Used by Comment.fetchMessage()
      messageRef,
      threadId: message.threadId,
      inboxId: message.inboxId,
      text: comment,
      mentionedUsers,
      unreadUsers: mentionedUsers,
      attachments: attachments.map(toCommentAttachment),
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };

    // コメントの参照を作成する
    const commentRef = store.doc('comments', commentId);

    // トランザクション内で実行する
    await runTransaction(db9, async (tx) => {
      // コメントを作成する
      await tx.set(commentRef, commentData);
      // メッセージの最新コメント、コメント数、更新日時を更新する
      await tx.update(messageRef, {
        latestComment: {
          commentId: commentRef.id,
          ...commentData,
        },
        [`readers.${store.me.id}`]: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    });
    logEvent(eventNames.add_comment, { mentioned: mentionedUsers.length > 0 });
  };

  const onClickSend = async () => {
    if (!comment || uploadingAttachments.length || removingAttachments.length) {
      return;
    }
    setAdding(true);
    await addCommentToMessage();
    await addComment(comment);
    setCommentId(doc(store.collection('comments')).id);
    setAttachments([]);
    setAdding(false);
    setComment('');
    setRows(1);
    focusMention();
  };

  const focusMention = () => mentionRef.current?.focus();

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) =>
    isCmdOrCtrlEnter(e) && onClickSend();

  // 名前順
  const sortedGroups = groups.sort((groupA, groupB) =>
    groupA.name.localeCompare(groupB.name)
  );

  // ソートの順番はチーム内のメンバーは上、チーム外は下
  const sortedUsers = [...users].sort((userA, userB) => {
    const isUserATeamMember = teamMembers.some(
      (member) => member.id === userA.id
    );
    const isUserBTeamMember = teamMembers.some(
      (member) => member.id === userB.id
    );

    if (isUserATeamMember && !isUserBTeamMember) {
      return -1;
    }

    if (!isUserATeamMember && isUserBTeamMember) {
      return 1;
    }

    return 0;
  });

  return (
    <div className="sticky bottom-0 z-10 bg-white shadow-add-comment sm:bg-sumi-50 sm:shadow-none ">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <DropzoneWrapper>
          {isDragActive && (
            <DropMessage>
              <Icon icon={NoteAdd} size={24} className={'text-sumi-800'} />
              ドロップしてファイルをアップロード
            </DropMessage>
          )}
          <DropzoneInner isDragActive={isDragActive}>
            <Wrapper>
              <PCOnly>
                <UserAvatar user={me} />
              </PCOnly>
              <CommentWrapper>
                <div
                  className={wrapper({ disabled: disabled || adding })}
                  style={{ border: '1px solid #D8D8DB' }}
                >
                  {message && (
                    <>
                      <CustomUpload
                        beforeUpload={beforeUpload}
                        fileList={attachments}
                        showUploadList={false}
                      >
                        <div ref={uploadRef} />
                      </CustomUpload>
                      <Button
                        className="m-0 border-none text-sea-500 outline-none disabled:text-sumi-400"
                        type="default"
                        icon="plus"
                        onClick={showUploadDialog}
                        disabled={disabled || adding}
                      />
                    </>
                  )}
                  <InputWrapper>
                    <Attachments
                      attachments={attachments.map(toCommentAttachment)}
                      onRemove={onRemove}
                    />
                    <TextAreaWrapper onKeyDown={onKeyDown}>
                      {useMention ? (
                        <Mentions
                          className={mentions({ disabled })}
                          rows={rows}
                          placeholder={placeholder}
                          value={comment}
                          onChange={onChange}
                          disabled={disabled || adding}
                          ref={mentionRef}
                          placement="top"
                        >
                          <Option value="all">
                            <Avatar name="全員" size="small" />
                            <Name>all（チーム全員にメンションします）</Name>
                          </Option>
                          {sortedGroups.map((g) => (
                            <Option value={g.name} key={g.id}>
                              <Avatar name={g.name} size="small" />
                              <Name>{g.name}</Name>
                            </Option>
                          ))}
                          {sortedUsers.map((u) => {
                            const isTeamMember = teamMembers.some(
                              (member) => member.id === u.id
                            );
                            return isTeamMember ? (
                              <Option value={u.name} key={u.id}>
                                <Avatar user={u} size="small" />
                                <Name>{u.name}</Name>
                              </Option>
                            ) : (
                              <Option value={u.name} key={u.id} disabled>
                                <Avatar user={u} size="small" />
                                <Name>(チーム外){u.name}</Name>
                              </Option>
                            );
                          })}
                        </Mentions>
                      ) : (
                        <TextArea
                          className="resize-none border-none outline-none"
                          placeholder={placeholder}
                          value={comment}
                          onChange={(e) => onChange(e.target.value)}
                          ref={mentionRef}
                          disabled={disabled || adding}
                          autoSize
                        />
                      )}
                    </TextAreaWrapper>
                  </InputWrapper>
                  <button
                    className="mx-2 flex h-8 w-8 cursor-pointer items-center justify-center bg-transparent p-0 text-sea-500 disabled:pointer-events-none disabled:text-sumi-400 disabled:hover:bg-transparent"
                    disabled={disabled || adding || comment === ''}
                    onClick={onClickSend}
                    aria-label="コメントを追加"
                  >
                    <Icon icon={AddCommentSubmit} size={24} />
                  </button>
                </div>
              </CommentWrapper>
            </Wrapper>
          </DropzoneInner>
        </DropzoneWrapper>
      </div>
    </div>
  );
};

const DropzoneWrapper = styled.div`
  position: relative;
`;

const DropzoneInner = styled.div<{ isDragActive: boolean }>`
  ${({ isDragActive }) =>
    isDragActive &&
    css`
      opacity: 0.1;
    `};
`;

const DropMessage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.common.hover};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 12px;
`;

const UserAvatar = styled(Avatar)`
  margin-right: 7px;
`;

const CustomUpload = styled(Upload)<PropsWithChildren<UploadProps>>`
  .ant-upload-select-text {
    display: none !important;
  }

  .ant-upload-list::before,
  .ant-upload-list::after {
    content: none;
  }

  .ant-upload-list-item-name {
    cursor: pointer;
  }
`;

const CommentWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
`;

const InputWrapper = styled.div`
  flex: 1;
  width: 0;
`;

const TextAreaWrapper = styled.div`
  margin-top: 0;

  textarea {
    // メンション使用なし
    padding-top: 6px;
  }
`;

const Name = styled.span`
  margin-left: 4px;
`;
