import { estimateServerTimestampsOption } from './common';
import moment from 'moment';
import firebase from 'firebase/compat/app';

export interface ChatData {
  type: string;
  text: string;
  creator: string;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

export class Chat {
  private _id: string;
  private _ref: firebase.firestore.DocumentReference<ChatData>;
  private _data: ChatData;
  private _date: moment.Moment;
  constructor(doc: firebase.firestore.DocumentSnapshot<ChatData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for chat#${doc.id}`);
    }
    this._data = data;

    this._ref = doc.ref;
    this._date = moment(this._data.createdAt.toMillis()); // 毎回生成するのはコストがかかるため
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get date() {
    return this._date;
  }

  get type() {
    return this._data.type;
  }

  get text() {
    return this._data.text;
  }

  get creator() {
    return this._data.creator;
  }

  get createdAt() {
    return this._data.createdAt;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }
}
