import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AutoReplyEditModal from '../Inboxes/AutoReplyEditModal';
import { Inbox, InboxAutoReply, InboxData } from 'lib';
import firebase from 'firebase/compat';
import { observer } from 'mobx-react';
import { AutoReplyField } from '../Inboxes/fields';

type Props = {
  inbox: Inbox;
  autoReplySupported: boolean;
  update: (
    ref: firebase.firestore.DocumentReference<InboxData>,
    data: Partial<InboxData>
  ) => Promise<any>;
  disabled?: boolean;
};

export const MyAutoReply: React.FC<Props> = observer((props) => {
  const { inbox } = props;

  const [autoReply, setAutoReply] = useState<InboxAutoReply | null>(null);

  useEffect(() => {
    if (inbox) {
      setAutoReply(inbox.autoReply ?? null);
    }
  }, [inbox]);

  const handleSaveAutoReplySettings = async (
    settings: InboxAutoReply,
    closeModal: () => void
  ) => {
    setAutoReply(settings);

    await props.update(inbox.ref, {
      ...inbox,
      autoReply: settings,
    });
    closeModal();
  };

  return (
    <Wrapper>
      <AutoReplyField
        autoReply={autoReply}
        notSupported={!props.autoReplySupported}
        renderModal={({ close: onClose }) => {
          return (
            <AutoReplyEditModal
              title={`自動返信設定`}
              settings={autoReply ?? undefined}
              onClose={onClose}
              onSave={(autoReply) =>
                handleSaveAutoReplySettings(autoReply, onClose)
              }
              visible={true}
            />
          );
        }}
        disabled={props.disabled}
      />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding 40px 16px 40px 0px;
`;
