import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Modal, Button } from 'antd';
import { Comment } from '../../Common/Comment';
import { H2 } from '../../Common/H2';
import Avatar from '../../Common/Avatar';
import Tooltip from '../../Common/Tooltip';
import firebase from '../../../firebase';
import { db } from '../../../firebase';
import {
  Member,
  Profile,
  NameAndEmail,
  RemoveButton,
  Name,
} from '../../Common';

class MemberModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      members: [],
      loading: false,
    };
  }

  to = (link) => {
    this.setState({
      open: false,
      members: [],
    });
    this.props.setOpen(false);
    this.props.history.push(link);
    this.props.store.closeDrawer();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open,
        members: [],
      });
    }
  }

  handleCancel() {
    this.setState({ open: false });
    this.props.setOpen(false);
  }

  async handleOk() {
    this.setState({ loading: true });
    const members = [
      this.props.store.me.id,
      ...this.state.members.map((m) => m.id),
    ];
    let roomsSnapshot = await db
      .collection(`companies/${this.props.store.signInCompany}/rooms`)
      .where('members', 'array-contains', this.props.store.me.id)
      .get();

    const room = roomsSnapshot.docs.find((doc) => {
      if (doc.data().members.length === members.length) {
        const ex = doc.data().members.find((m) => !members.includes(m));
        if (ex) return false;
        return true;
      }
      return false;
    });

    if (room) {
      this.setState({ loading: false });
      this.to(`/rooms/${room.id}`);
      return;
    }

    const roomRef = await db
      .collection(`companies/${this.props.store.signInCompany}/rooms`)
      .add({
        members,
        isGroup: members.length > 2,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    this.setState({ loading: false });
    this.to(`/rooms/${roomRef.id}`);
    this.props.store.closeDrawer();
  }

  removeUser(u) {
    const index = this.state.members.findIndex((m) => m.id === u.id);
    const { members } = this.state;
    members.splice(index, 1);
    this.setState({
      members,
    });
  }

  addUser(u) {
    this.setState({
      members: [...this.state.members, u],
    });
  }

  render() {
    const { store } = this.props;
    const { loading, open, members } = this.state;
    const remainingMembers = store.usersLoading
      ? []
      : (() => {
          const { users, me } = store;
          const toExclude = new Set([me.id, ...members.map(({ id }) => id)]);
          return users.filter((u) => !toExclude.has(u.id));
        })();
    return (
      <Modal
        title="メンバー設定"
        visible={open}
        onCancel={this.handleCancel.bind(this)}
        footer={[
          <Button key="back" onClick={this.handleCancel.bind(this)}>
            キャンセル
          </Button>,
          <Button
            loading={loading}
            disabled={loading || members.length === 0}
            key="submit"
            type="primary"
            onClick={this.handleOk.bind(this)}
          >
            作成
          </Button>,
        ]}
      >
        {members.length > 0 && <H2>追加済みのメンバー</H2>}
        {members.map((u) => (
          <Member style={{ marginBottom: '20px' }} key={u.id}>
            <Profile>
              <Avatar user={u} />
              <NameAndEmail>
                <Name>{u.name}</Name>
                <Comment>{u.email}</Comment>
              </NameAndEmail>
            </Profile>
            <Tooltip title="権限がありません">
              <RemoveButton type="danger" onClick={() => this.removeUser(u)}>
                削除
              </RemoveButton>
            </Tooltip>
          </Member>
        ))}
        {remainingMembers.length > 0 && <H2>メンバーを追加する</H2>}
        {remainingMembers.map((u) => (
          <Member key={u.id}>
            <Profile>
              <Avatar user={u} />
              <NameAndEmail>
                <Name>{u.name}</Name>
                <Comment>{u.email}</Comment>
              </NameAndEmail>
            </Profile>
            <Tooltip title="権限がありません">
              <Button type="primary" onClick={() => this.addUser(u)}>
                追加
              </Button>
            </Tooltip>
          </Member>
        ))}
      </Modal>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(MemberModal);
