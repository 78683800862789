import styled from 'styled-components';
import { Button } from 'antd';
export const Member = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Profile = styled.div`
  width: 392px;
  display: flex;
  align-items: center;
`;

export const NameAndEmail = styled.div`
  margin-left: 10px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const RemoveButton = styled(Button)`
  margin-left: 12px;
`;

export const Name = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #44425e;
`;
