import { ReactNode, useLayoutEffect, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { tv } from 'tailwind-variants';

type Props = {
  placement: 'left' | 'right';
  children: ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  container?: HTMLElement | null;
  modal?: boolean;
  className?: string;
  overlayClassName?: string;
};

const overlay = tv({
  base: 'pointer-events-none fixed inset-0 w-full bg-black/50',
  variants: {
    open: {
      true: 'pointer-events-auto opacity-100',
      false: 'pointer-events-none opacity-0',
    },
    animation: {
      true: 'transition-opacity duration-300',
    },
  },
});

const content = tv({
  base: 'absolute top-0 h-full bg-white',
  variants: {
    placement: {
      left: 'left-0',
      right: 'right-0',
    },
    open: {
      true: 'pointer-events-auto',
      false: 'pointer-events-none',
    },
    animation: {
      true: 'transition-transform duration-300',
    },
  },
});

export const Drawer = ({
  placement,
  children,
  open,
  onOpenChange,
  container,
  modal = true,
  className,
  overlayClassName,
}: Props) => {
  const [animation, setAnimation] = useState(true);
  useLayoutEffect(() => {
    setAnimation(false);
    const id = setTimeout(() => {
      setAnimation(true);
    }, 100);
    return () => {
      clearTimeout(id);
      setAnimation(true);
    };
  }, [placement]);
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange} modal={modal && open}>
      <Dialog.Portal forceMount container={container}>
        {open && <Dialog.Overlay />}
        <div className="pointer-events-none fixed left-0 top-0 z-20 h-full w-full overflow-x-hidden">
          <div
            className={overlay({
              open,
              animation,
              className: overlayClassName,
            })}
          />
          <div
            className={content({ placement, open, animation })}
            style={{
              ['--drawer-direction' as never]:
                placement === 'left' ? '-1' : '1',
              transform: `translateX(${
                open ? '0' : `calc(100% * var(--drawer-direction))`
              })`,
            }}
          >
            <Dialog.Content className={className} forceMount>
              {children}
            </Dialog.Content>
          </div>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
