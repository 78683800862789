import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Form, message, Modal, Result, Tag, Upload } from 'antd';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import firebase from 'firebase.js';
import EditableTagGroup from './editableTagGroup';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import debounce from 'lodash.debounce';
import {
  arrayDiff,
  convertNtoBr,
  escapeHtml,
  extractEmail,
  extractName,
  extractNonInlineAttachments,
  extractUsedAttachments,
  toRecipients,
} from 'lib';
import { From } from './From';
import Signature from './signature';
import Quote from './quote';
import { sendMessage } from './sendMessageFunc';
import Sending from './sending';
import { PCOnly } from '../../Common/MediaQuery';
import { uid as rcuid } from './rcuid';
import { eventNames, logEvent } from '../../../analytics';
import { generateAttachment } from '../Conversations/util';
import { parseReservedWords } from '../../Common/Editor/util';
import Template from './Template';
import { DatePickerModal } from './DatePickerModal';
import PreviewImageModal, {
  isPreviewableImageMimeType,
} from '../../Common/Modal/previewImageModal';
import {
  downloadAttachment,
  fetchFromPath,
  generateStorageAttachmentName,
  isCmdOrCtrl,
} from '../../../util';
import { formatFileSize } from 'utils/file';
import { ReactComponent as PopUpIcon } from '../../../assets/icons/popup.svg';
import { createCompletionFunction } from '../../../functions';
import { AiPromptDialog } from '../../Common/Editor/AiPromptDialog/AiPromptDialog';
import {
  deleteDoc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import CreateMessageFooter, { ToolbarItem } from './CreateMessageFooter';
import { tv } from 'tailwind-variants';
import { Icon } from '../../../components/basics';
import { MessageReply, NoteAdd } from '../../../components/icons';
import { TemplateModal } from './Template/TemplateModal';
import { isSP } from 'shared/util';
import media from 'styled-media-query';
import { store } from '../../../providers/StoreProvider';
import { messagesAtom } from '../../../atoms/firestore/message';
import CreateMessageTopBar, { TopbarItem } from './CreateMessageTopBar';
import SimpleBar from 'simplebar-react';
import { EditorToolbarWithControls } from '../../Common/Editor/EditorToolbar/EditorToolbarWithControls';
import { CreateMessageWysiwygEditor } from './CreateMessageWysiwygEditor';
import { EditorBubbleMenu } from './EditorBubbleMenu';
import { statusesAtomFamily } from 'atoms/firestore/customStatuses';
import {
  buildFrontMessageStatus,
  getNextStatus,
} from 'utils/frontMessageStatus';
import { mentionedMessagesAtom } from '../../../atoms/firestore/mention';
import { commentedMessagesAtom } from '../../../atoms/firestore/comment';
import { sentAtom } from '../../../atoms/firestore/sent';
import { draftsAtom, modalDraftAtom } from '../../../atoms/firestore/draft';
import { useAtomValue } from 'jotai';

const { confirm } = Modal;

const FROM_DROPPABLE_ID = 'from';
const CC_DROPPABLE_ID = 'cc';
const BCC_DROPPABLE_ID = 'bcc';

const editorWrapper = tv({
  base: 'relative -mx-4 flex flex-col overflow-hidden focus:shadow',
  variants: {
    isReply: {
      true: '',
      false: 'h-[260px] min-h-[260px]',
    },
    inModal: {
      true: 'h-full',
    },
  },
  compoundVariants: [
    {
      isReply: false,
      inModal: false,
      className: 'sm:h-[400px] sm:min-h-[400px]',
    },
  ],
  defaultVariants: {
    isReply: false,
    inModal: false,
  },
});

class Index extends Component {
  constructor(props) {
    super(props);
    const {
      inboxId,
      teamId,
      to,
      cc,
      bcc,
      subject,
      originalSubject,
      body,
      bodyHtml,
      useQuote,
      attachments,
      plainTextMode,
      followupAt,
    } = props.draft;

    this.state = {
      templateVisible: false,
      textCompletionVisible: false,
      textConvertVisible: false,
      customToolbarVisible: false,
      originalInboxId: inboxId,
      inboxId,
      teamId,
      to,
      cc,
      bcc,
      subject,
      body,
      bodyHtml: bodyHtml || convertNtoBr(body), // HTML対応前のデータは改行コードを<br/>に変換して渡す
      signature: null,
      useQuote,
      attachments,
      uploadingAttachments: [],
      updatingAttachments: false,
      removingAttachments: [],
      isUpdating: false,
      updatedAt: null,
      ccOpen: cc.length > 0,
      bccOpen: bcc.length > 0,
      subjectOpen:
        subject !== `${originalSubject}` &&
        subject !== `Re: ${originalSubject}`, // 変更されている場合のみ表示する
      // @type 'schedule' | 'followup'
      datePickerTarget: null,
      // @type moment.Moment
      datePickerInitialValue: null,
      plainTextMode,
      scheduledAt: null,
      followupAt: followupAt ?? null,
      previewImage: null,
      previewImageUrl: null,
      dndSource: null, // ドラッグ元のDroppableデータ
      dndItem: null, // Draggableデータ
      readOnly: false,
      uniqueId: `_${uuidv4()}`,
      ignoreUnsubscribes: null,
      toName: null,
      editorHandle: undefined,
      signatureEditorHandle: undefined,
      htmlToolbarVisible: false,
      lateDeleteAttachments: [],
    };

    this.subjectRef = React.createRef();
    this.uploadFieldRef = React.createRef();
    this.updateDraftDebounced = debounce(this.updateDraft, 600);
    this.unsubscribeDraft = null;

    this._autoFocusTo = to.length === 0;
  }

  async componentDidMount() {
    const { draft } = this.props;

    // デフォルト署名を設定
    this.fetchToAddressContactName(draft.to).then(() => {
      const parsedSignature = this.parseSignature(
        draft.to,
        draft.inboxId,
        draft.signature
      );
      this.setSignature(parsedSignature);
    });

    const diff = moment().diff(moment(draft.updatedAt.toDate()), 'minutes');
    // 下書きの更新から10分以上経っていて送信中だった場合、送信中ではないはずなので戻す。
    if (draft.isSending && diff >= 10) {
      await updateDoc(draft.ref, {
        isSending: false,
        updatedAt: serverTimestamp(),
      });
    }

    const { subject } = this.state;
    if (this._autoFocusTo) {
      // Toにフォーカスする場合、Toのコンポーネント内でフォーカスするため、こちらではフォーカスしない
      return;
    }

    if (!subject) {
      // 件名がない場合、件名フォーカスする
      this.focusSubject();
      return;
    }

    // 本文がない場合、本文にフォーカスする
    this.focusBody();
  }

  async componentWillUnmount() {
    this.updateDraftDebounced.cancel();
    this.unsubscribeDraft?.();
    await this.deleteLateDeleteAttachments();
  }

  parseSignature = (to, inboxId, signature) => {
    // @NOTE
    // 下書きのURLを直指定で開くとInboxがundefinedしてエラーになるので
    // Inboxがundefinedなときはskipする
    if (!signature || !this.props.store.getInbox(inboxId)) return null;
    return {
      ...signature,
      body: this._parseReservedWords(to, inboxId, signature.body),
      bodyHtml: this._parseReservedWords(to, inboxId, signature.bodyHtml),
    };
  };

  // inbox変更する場合にccとbccを再生成する
  createCcAndBcc = (currentInboxId) => {
    const prevInboxId = this.state.inboxId;
    const prevInbox = this.props.store.getInbox(prevInboxId);
    const currentInbox = this.props.store.getInbox(currentInboxId);

    const { to, cc, bcc } = this.state;
    // 追加されていた自動ccを削除
    const removedAutoCc = cc.filter(
      (c) => !prevInbox.autoCcs.some((acc) => extractEmail(c) === acc)
    );

    // 追加されていた自動bccを削除
    const removedAutoBcc = bcc.filter(
      (b) => !prevInbox.autoBccs.some((abcc) => extractEmail(b) === abcc)
    );

    const currentCc = [
      ...removedAutoCc,
      ...currentInbox.autoCcs.filter(
        (acc) =>
          ![...removedAutoCc, ...to, ...removedAutoBcc].some(
            (nc) => extractEmail(nc) === acc
          )
      ),
    ];

    const currentBcc = [
      ...removedAutoBcc,
      ...currentInbox.autoBccs.filter(
        (ac) =>
          ![...removedAutoBcc, ...to, ...currentCc].some(
            (nc) => extractEmail(nc) === ac
          )
      ),
    ];
    return [currentCc, currentBcc];
  };

  setInbox = async (inboxId, teamId) => {
    const { inboxId: prevInboxId, teamId: prevTeamId } = this.state;
    if (prevInboxId === inboxId) return;

    // 自動cc/bccを追加
    const [cc, bcc] = this.createCcAndBcc(inboxId);
    const teamChanged = prevTeamId !== teamId;
    this.setState(
      {
        inboxId,
        teamId,
        cc,
        bcc,
        ccOpen: cc.length > 0,
        bccOpen: bcc.length > 0,
        updatingAttachments: teamChanged || this.state.updatingAttachments,
      },
      this.updateDraft
    );

    if (!teamChanged) return;

    // 添付ファイルをアップデートする
    const { attachments } = this.state;
    const updateAttachmentsPromise = attachments.map(async (attachment) => {
      await firebase
        .storage()
        .ref()
        .child(attachment.storagePath)
        .updateMetadata({ customMetadata: { teamId } });
    });
    await Promise.all(updateAttachmentsPromise);
    this.setState({ updatingAttachments: false });
  };

  focusSubjectOrBody = () => {
    if (this.state.subjectOpen) {
      // 件名が開いている場合は件名にフォーカスする
      this.focusSubject();
      return;
    }

    // 開いていない場合は本文にフォーカスする
    this.focusBody();
  };

  focusSubject = () => {
    this.subjectRef.current.focus();
  };

  focusBody = () => {
    this.state.editorHandle?.editor?.commands.focus();
  };

  setTo = (to) => {
    this.setState({ to }, this.updateDraft);
  };

  setCc = (cc) => {
    this.setState({ cc }, this.updateDraft);
  };

  setBcc = (bcc) => {
    this.setState({ bcc }, this.updateDraft);
  };

  onDragStart = ({ source, address }, event) => {
    const { x, y } = event.currentTarget.getBoundingClientRect();
    event.dataTransfer.setDragImage(
      event.currentTarget,
      event.pageX - x,
      event.pageY - y
    );
    this.setState({ dndSource: source, dndItem: address });
  };

  onDragEnd = (target) => {
    const { dndSource, dndItem, to, cc, bcc } = this.state;
    if (!dndSource || !dndItem) {
      this.setState({
        dndSource: null,
        dndItem: null,
      });
      return;
    }

    let newTo = [...to];
    let newCc = [...cc];
    let newBcc = [...bcc];

    // ドロップ元からアドレスを削除
    if (dndSource === FROM_DROPPABLE_ID) {
      newTo = newTo.filter((address) => address !== dndItem);
    }
    if (dndSource === CC_DROPPABLE_ID) {
      newCc = newCc.filter((address) => address !== dndItem);
    }
    if (dndSource === BCC_DROPPABLE_ID) {
      newBcc = newBcc.filter((address) => address !== dndItem);
    }

    // ドロップ先にアドレスを追加
    if (target === FROM_DROPPABLE_ID) {
      newTo = [...newTo, dndItem];
    }
    if (target === CC_DROPPABLE_ID) {
      newCc = [...newCc, dndItem];
    }
    if (target === BCC_DROPPABLE_ID) {
      newBcc = [...newBcc, dndItem];
    }

    this.setState(
      {
        to: newTo,
        cc: newCc,
        bcc: newBcc,
        dndSource: null,
        dndItem: null,
      },
      this.updateDraft
    );
  };

  // To,Cc,Bcc内で重複していないかを返す。
  isDuplicatedRecipients = (
    newValue,
    options = { to: true, cc: true, bcc: true }
  ) => {
    const newEmail = extractEmail(newValue);

    // Toに一致しないか
    if (
      options.to &&
      this.state.to.map((t) => extractEmail(t)).indexOf(newEmail) !== -1
    ) {
      // Toに存在する場合
      return true;
    }
    // Ccに一致しない
    if (
      options.cc &&
      this.state.cc.map((c) => extractEmail(c)).indexOf(newEmail) !== -1
    ) {
      // Toに存在する場合
      return true;
    }
    // Bccに一致しないか
    if (
      options.bcc &&
      this.state.bcc.map((b) => extractEmail(b)).indexOf(newEmail) !== -1
    ) {
      // Toに存在する場合
      return true;
    }
    return false;
  };

  getRecipientsLength = () => {
    const { to, cc, bcc } = this.state;
    return to.length + cc.length + bcc.length;
  };

  setSubject = (e) => {
    this.setState({ subject: e.target.value }, this.updateDraftDebounced);
  };

  setBody = (text, html) => {
    this.setState(
      {
        body: text,
        bodyHtml: html,
      },
      this.updateDraftDebounced
    );
  };

  /**
   * @param handle {EditorHandle}
   * @param file {File}
   * @return {Promise<void>}
   */
  onInsertImage = async (handle, file) => {
    await this.onUploadImage?.(file, (url) => {
      handle.insertImage(url, file.content_id);

      this.setState(
        {
          bodyHtml: handle.editor.getHTML(),
        },
        this.updateDraftDebounced
      );
    });
  };

  /**
   * @param {moment.Moment} at
   * @param {boolean|undefined} ignoreUnsubscribes
   * @return {Promise<void>}
   */
  handleSchedule = async (at, ignoreUnsubscribes) => {
    const scheduledAt = firebase.firestore.Timestamp.fromDate(at.toDate());
    if (ignoreUnsubscribes != null) {
      this.setState({ ignoreUnsubscribes });
    }
    this.setState(
      {
        scheduledAt: scheduledAt,
      },
      async () => {
        await this.deleteLateDeleteAttachments();
        await this.updateDraft();
        if (this.props.deleteCallback) this.props.deleteCallback();

        if (this.props.draft.isReply) {
          await this.props.store.companyCollection('events').add({
            user: this.props.store.me.id,
            name: this.props.store.me.name,
            teamId: this.props.draft.teamId,
            messageId: this.props.draft.inReplyToMessageId,
            type: `draft:schedule${
              this.props.draft.isForwarded ? ':forward' : ''
            }`,
            text: `${
              this.props.store.me.name
            }が送信予約を行いました。送信予定: ${at.format('M月D日 HH:mm')}`,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }

        message.success('送信を予約しました。');
      }
    );
  };

  handleFollowup = async (at) => {
    const followupAt = at
      ? firebase.firestore.Timestamp.fromDate(at.toDate())
      : null;
    this.setState(
      {
        followupAt,
      },
      async () => {
        await this.updateDraft();
      }
    );
  };

  /**
   * @param {moment.Moment} at
   * @param {boolean} ignoreUnsubscribes
   * @param {string} source
   * @return {Promise<void>}
   */
  handleDatePicker = async (at, ignoreUnsubscribes, source = 'schedule') => {
    switch (this.state.datePickerTarget || source) {
      case 'followup':
        return this.handleFollowup(at);
      case 'schedule':
        return this.handleSchedule(at, ignoreUnsubscribes);
      default:
        break;
    }
  };

  /**
   * @param e {KeyboardEvent}
   * @return {Promise<void>}
   */
  onKeyDownInSubject = async (e) => {
    if (e.key !== 'Enter') {
      return;
    }
    e.preventDefault(); // 本文にフォーカスした際、改行が入ることを防いでいる
    if (isCmdOrCtrl(e)) {
      // win/mac
      // ctrl/cmd + Enter
      await this.handleSubmitWithNextStatus();
    }
  };

  setSignature = (signature) => {
    if (signature === null) {
      this.setState({ signature });
      return;
    }
    const newSignature = {
      ...signature,
      ...{
        bodyHtml: `${
          this.parseReservedWords(signature.bodyHtml, {
            escape: true,
          }) ||
          this.parseReservedWords(convertNtoBr(escapeHtml(signature.body)), {
            escape: true,
          })
        }`,
        body: this.parseReservedWords(signature.body),
      },
    };
    this.setState({ signature: newSignature }, this.updateDraftDebounced);
    this.state.signatureEditorHandle?.setHtml(newSignature.bodyHtml);
  };

  /**
   * @param text {string}
   * @param html {string}
   */
  setSignatureBody = (text, html) => {
    this.setState(
      {
        signature: {
          ...this.state.signature,
          body: text,
          bodyHtml: html,
        },
      },
      this.updateDraftDebounced
    );
  };

  addSignature = () => {
    const { teamId, inboxId } = this.state;
    const inbox = this.props.store.getInbox(inboxId);
    this.setSignature(
      this.props.store.getSignature(inbox.defaultSignatureId)?.signature ||
        this.props.store.getFirstSignature(teamId)?.signature ||
        null
    );
  };

  removeSignature = () => {
    this.setSignature(null);
    this.hideCustomToolbar();
  };

  addQuote = () => {
    this.setState({ useQuote: true }, this.updateDraftDebounced);
  };

  removeQuote = () => {
    this.setState({ useQuote: false }, this.updateDraftDebounced);
  };

  parseReservedWords = (body, parseOptions = {}) => {
    const { to, inboxId } = this.state;
    return this._parseReservedWords(to, inboxId, body, parseOptions);
  };

  getParseItems = (to, inbox) => {
    let toName = to.length > 0 ? extractName(to[0]) : null;
    if (this.state.toName) {
      toName = this.state.toName;
    }
    return {
      to: {
        name: toName,
        email: to.length > 0 ? extractEmail(to[0]) : null,
      },
      me: {
        name: this.props.store.me.name,
        email: this.props.store.me.email,
      },
      from: {
        name: inbox?.name,
        email: inbox?.email,
      },
    };
  };

  _parseReservedWords = (to, inboxId, body, parseOptions = {}) => {
    const inbox = this.props.store.getInbox(inboxId);
    const parseItems = this.getParseItems(to, inbox);
    return parseReservedWords(body, parseItems, parseOptions);
  };

  setTemplate = async (template) => {
    const { to, cc, bcc, subject, subjectOpen, bodyHtml, body } = this.state;

    // @NOTE いまTo,Cc,Bccに設定されているメールアドレスは除く
    // たとえば、テンプレートのBccにcontact@onebox.tokyoがある場合でも
    // いま作成中のメールのTo,Cc,Bccにcontact@onebox.tokyoがあったら
    // 追加で設定しないようにしている
    const allAddress = to.concat(cc).concat(bcc).map(extractEmail);
    const templateTo = arrayDiff(toRecipients(template.to), allAddress);
    const templateCc = arrayDiff(toRecipients(template.cc), allAddress);
    const templateBcc = arrayDiff(toRecipients(template.bcc), allAddress);

    // @NOTE 現在設定されている宛先にテンプレートの宛先を結合する
    const newTo = to.concat(templateTo);
    const newCc = cc.concat(templateCc);
    const newBcc = bcc.concat(templateBcc);

    let parsedNewTo = null;

    if (newTo.length > 0) {
      parsedNewTo = await this.fetchToAddressContactName(newTo);
    }

    const newBodyHtml = `${body.trim().length === 0 ? '' : bodyHtml}${
      this.parseReservedWords(template.bodyHtml, {
        overrideToName: parsedNewTo,
        escape: true,
      }) ||
      this.parseReservedWords(convertNtoBr(escapeHtml(template.body)), {
        overrideToName: parsedNewTo,
        escape: true,
      })
    }`;

    this.setState(
      {
        to: newTo,
        cc: newCc,
        bcc: newBcc,
        ccOpen: newCc.length > 0,
        bccOpen: newBcc.length > 0,
        subject: template.subject || subject,
        subjectOpen: template.subject ? true : subjectOpen,
        // MEMO: ユーザがなにも入力していない場合、テンプレートを追加すると先頭に空行ができてしまうことを防いでいる
        bodyHtml: newBodyHtml,
        body:
          body +
          '\n' +
          this.parseReservedWords(template.body, {
            overrideToName: parsedNewTo,
          }),
        templateVisible: false,
      },
      this.updateDraft
    );

    this.state.editorHandle.setHtml(newBodyHtml);

    await this.uploadTemplateAttachments(template.attachments);
    logEvent(eventNames.apply_template, { template_id: template.id });
  };

  uploadTemplateAttachments = async (attachments) => {
    if (attachments.length === 0) return;
    // 逐次実行したいので、for of で書いている
    for (const attachment of attachments) {
      // Firebase Storageではコピーできないので、一度BlobとしてダウンロードしてからFileへ変換する
      const key = uuidv4();
      message.loading({
        content: `添付ファイル ${attachment.name} を取得中です`,
        key,
      });
      const ref = firebase.storage().ref(attachment.storagePath);
      const url = await ref.getDownloadURL();
      const res = await fetch(url);
      if (!res.ok) {
        message.error({
          content: `添付ファイル ${attachment.name} の取得に失敗しました`,
          key,
        });
        return;
      }
      message.success({
        content: `添付ファイル ${attachment.name} を取得しました`,
        key,
        duration: 1,
      });
      const file = new File([await res.blob()], attachment.name);
      file.uid = rcuid(); // rc-uploadを経由していないため、uidが作られず、表示時にエラーとなる。そのため、ここでuidを手動で生成している
      await this.validateFileAndUpload(file);
    }
  };

  // 更新
  updateDraft = async () => {
    this.setState({ isUpdating: true });
    const { draft } = this.props;
    const {
      originalInboxId,
      inboxId,
      to,
      cc,
      bcc,
      subject,
      body,
      bodyHtml,
      signature,
      teamId,
      useQuote,
      attachments,
      plainTextMode,
      scheduledAt,
      followupAt,
      ignoreUnsubscribes,
    } = this.state;

    const dedup = (recipients) => [...new Set(recipients)];

    await updateDoc(draft.ref, {
      originalInboxId,
      inboxId,
      teamId,
      to: dedup(to),
      cc: dedup(cc),
      bcc: dedup(bcc),
      subject,
      body,
      bodyHtml,
      signature,
      useQuote,
      attachments,
      plainTextMode,
      scheduledAt,
      followupAt,
      ignoreUnsubscribes,
      isSending: false,
      failedAt: null,
      updatedAt: serverTimestamp(),
    });

    this.setState({ isUpdating: false, updatedAt: moment() });
  };

  // 削除
  handleDelete = () => {
    const deleteDraft = this.deleteDraft;
    confirm({
      title: '下書きを破棄しますか？',
      content: '下書きを破棄すると内容は削除されます。',
      okText: '破棄する',
      cancelText: 'キャンセル',
      async onOk() {
        await deleteDraft();
      },
      onCancel() {},
      okType: 'danger',
      maskClosable: true,
    });
  };

  deleteDraft = async () => {
    const { store, draft } = this.props;
    const { teamId, inReplyToMessageId } = draft;
    if (draft.isReply) {
      // イベントを作成する
      await store.companyCollection('events').add({
        user: this.props.store.me.id,
        name: this.props.store.me.name,
        teamId: teamId,
        messageId: inReplyToMessageId,
        type: `draft:remove${draft.isForwarded ? ':forward' : ''}`,
        text: `${this.props.store.me.name}が${
          draft.isForwarded ? '転送' : '返信'
        }を取り消しました。`,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
    // 下書きを削除する
    await deleteDoc(this.props.draft.ref);
    logEvent(eventNames.remove_draft);
    if (this.props.deleteCallback) this.props.deleteCallback();
  };

  // タブを閉じた場合、メッセージ送信中だと確認ダイアログを出す
  handleConfirmCloseTab = (e) => {
    e.preventDefault();
    e.returnValue =
      'メールの送信中です。ウィンドウもしくはタブを閉じるとメールが送信されませんがよろしいですか？';
  };

  // 送信後の遷移先リンク
  /**
   * @param message {Object|null}
   * @param isThread {boolean}
   * @return {string|null}
   */
  toLink = (message, isThread) => {
    if (this.props.location.pathname.startsWith('/me/drafts')) {
      if (!message) return '/me/drafts';
      return message.isReply
        ? `/me/drafts/${message.id}/teams/${message.teamId}/inboxes/${message.inboxId}`
        : `/me/drafts/${message.id}`;
    }

    const tab = this.props.store.messageFilterStore.view || 'messages';

    if (this.props.location.pathname.startsWith('/me/assigned')) {
      return message ? `/me/assigned/${tab}/${message.id}` : '/me/assigned';
    }

    const viewModeSuffix = isThread ? '?view=thread' : '';

    const { teamId, inboxId, tagId } = this.props.match.params;
    if (teamId && inboxId && tagId) {
      return message
        ? `/teams/${teamId}/inboxes/${inboxId}/tags/${tagId}/${tab}/${message.id}${viewModeSuffix}`
        : `/teams/${teamId}/inboxes/${inboxId}/tags/${tagId}/${tab}`;
    }

    if (teamId && tagId) {
      return message
        ? `/teams/${teamId}/tags/${tagId}/${tab}/${message.id}${viewModeSuffix}`
        : `/teams/${teamId}/tags/${tagId}/${tab}`;
    }

    if (teamId) {
      return message
        ? `/teams/${teamId}/${tab}/${message.id}${viewModeSuffix}`
        : `/teams/${teamId}/${tab}`;
    }

    return null;
  };

  // 送信
  handleSubmit = async (status) => {
    if (!this.canSend()) return;

    const { draft, modalDraft } = this.props; // 送信対象の下書き
    const { draftId } = this.props.match.params; // 選択中の下書き

    const { sortedMessages: sortedSearchedMessages, inSearch } =
      this.props.store.searchStore;

    const pathname = this.props.location.pathname;
    let allMessagesAtom;
    if (pathname.startsWith('/me/mentioned')) {
      allMessagesAtom = mentionedMessagesAtom;
    } else if (pathname.startsWith('/me/commented')) {
      allMessagesAtom = commentedMessagesAtom;
    } else if (pathname.startsWith('/me/sent')) {
      allMessagesAtom = sentAtom;
    } else {
      allMessagesAtom = messagesAtom;
    }
    const allMessages = store.get(allMessagesAtom);

    await this.updateDraft();

    // 次の未対応のメッセージを表示する。
    let nextLink;
    let selectedMessageId = draft.inReplyToMessageId; // 選択中のメッセージ or 選択中の下書き
    let submitMessageId = draft.inReplyToMessageId; // 送信対象のメッセージ or 送信対象の下書き
    let messages = inSearch ? sortedSearchedMessages : allMessages.data;

    // "me/drafts"の場合メッセージ一覧ではなく下書き一覧なので値を入れ直す。
    const draftsMe = this.props.location.pathname.startsWith('/me/drafts');
    if (draftsMe) {
      // 新規作成のモーダルで送信した場合、選択と送信対象の下書きが異なる場合がある
      selectedMessageId = draftId;
      submitMessageId = draft.id;
      messages = store.get(draftsAtom).data;
    } else if (this.props.store.isInThreadView && draft.inReplyToMessageRef) {
      // スレッド表示時はthreadIdを使う
      const messageData = await getDoc(draft.inReplyToMessageRef);
      selectedMessageId = messageData.threadId;
      submitMessageId = messageData.threadId;
    }
    messages = messages ?? [];

    const inModal = modalDraft && modalDraft.id === draft.id;

    const selectedMessageIndex = messages.findIndex(
      ({ id }) => id === selectedMessageId
    );

    const hasSelectedMessage = selectedMessageIndex !== -1;
    const isSameSelectedAndSubmitMessage =
      selectedMessageId === submitMessageId;

    // メッセージリストが空になる場合
    if (
      !inModal &&
      hasSelectedMessage &&
      isSameSelectedAndSubmitMessage &&
      messages.length < 2
    ) {
      nextLink = this.toLink(null, this.props.store.isInThreadView);
    }

    // メッセージリストが空にならない場合
    if (
      !inModal &&
      hasSelectedMessage &&
      isSameSelectedAndSubmitMessage &&
      messages.length >= 2
    ) {
      const showMessage =
        selectedMessageIndex === messages.length - 1
          ? messages[selectedMessageIndex - 1]
          : messages[selectedMessageIndex + 1];
      nextLink = this.toLink(showMessage, this.props.store.isInThreadView);
    }

    if (nextLink) {
      this.props.history.push(nextLink);
    }

    // メッセージ送信前、イベントを登録する。
    window.addEventListener('beforeunload', this.handleConfirmCloseTab);

    await this.deleteLateDeleteAttachments();
    const { cancel } = await sendMessage(this.props.draft.id, status);

    // メッセージ送信後、イベントを削除する。
    window.removeEventListener('beforeunload', this.handleConfirmCloseTab);

    if (cancel) {
      if (nextLink) {
        this.props.history.goBack();
      }
      return;
    }

    logEvent(eventNames.send_message);
  };

  handleSubmitWithNextStatus = async () => {
    const draft = this.props.draft;
    const [, customStatuses] = store.get(statusesAtomFamily(draft.teamId));
    const nextStatus = getNextStatus(
      buildFrontMessageStatus(draft.status, customStatuses),
      customStatuses
    );
    await this.handleSubmit(nextStatus);
  };

  toggleHtmlToolbar = () => {
    if (this.state.plainTextMode) {
      confirm({
        title: 'プレーンテキストモードを解除しますか？',
        okText: '解除する',
        cancelText: '解除しない',
        onOk: async () => {
          this.setState(
            {
              htmlToolbarVisible: true,
              plainTextMode: false,
            },
            this.updateDraftDebounced
          );
          logEvent(eventNames.toggle_html_toolbar);
        },
        onCancel() {},
        maskClosable: true,
      });
      return;
    }
    this.setState({
      htmlToolbarVisible: !this.state.htmlToolbarVisible,
    });
    logEvent(eventNames.toggle_html_toolbar);
  };

  hideCustomToolbar = () => {
    this.setState({
      htmlToolbarVisible: false,
    });
  };

  showTemplateModal = async () => {
    await this.fetchToAddressContactName(this.props.draft.to);
    this.setState({
      templateVisible: true,
      customToolbarVisible: true,
    });
    logEvent(eventNames.show_template);
  };

  showTextCompletionModal = () => {
    this.setState({
      textCompletionVisible: true,
      customToolbarVisible: true,
    });
    logEvent(eventNames.show_template);
  };

  createCompletion = async (prompt) => {
    const { draft } = this.props;
    this.hideTextCompletionModal();
    message.loading({
      content: '本文を生成中です',
      key: draft.id,
      duration: 0,
    });
    await updateDoc(draft.ref, {
      isGenerating: true,
    });
    this.setState({
      readOnly: true,
    });
    this.unsubscribeDraft = onSnapshot(draft.ref, (doc) => {
      const { body, bodyHtml, isGenerating } = doc.data();

      this.setState({
        body,
        bodyHtml,
      });
      this.state.editorHandle.setHtml(bodyHtml);
      if (!isGenerating) {
        this.setState({
          readOnly: false,
        });
        this.unsubscribeDraft?.();
        this.unsubscribeDraft = null;
        message.success({
          content: `本文の生成が完了しました`,
          key: draft.id,
          duration: 2,
        });
      }
    });
    await createCompletionFunction({
      companyId: this.props.store.signInCompany,
      draftId: draft?.id,
      prompt,
    }).catch(async () => {
      this.unsubscribeDraft?.();
      this.unsubscribeDraft = null;
      message.error({
        content: '本文の生成に失敗しました',
        key: draft.id,
      });
      await updateDoc(draft.ref, {
        isGenerating: false,
      });
      this.setState({
        readOnly: false,
      });
    });
  };

  hideTemplateModal = () => {
    this.setState({
      templateVisible: false,
    });
  };

  hideTextCompletionModal = () => {
    this.setState({
      textCompletionVisible: false,
    });
  };

  hidePreviewImageModal = () => {
    this.setState({
      previewImage: null,
      previewImageUrl: null,
    });
  };

  movableToModal = () =>
    this.state.uploadingAttachments.length === 0 &&
    this.state.removingAttachments.length === 0 &&
    !this.state.updatingAttachments;

  canSend = () => {
    const {
      to,
      uploadingAttachments,
      removingAttachments,
      updatingAttachments,
    } = this.state;
    return (
      to.length > 0 &&
      uploadingAttachments.length === 0 &&
      removingAttachments.length === 0 &&
      !updatingAttachments
    );
  };

  showUploadDialog = () => {
    this.uploadFieldRef.current.click();
  };

  uploadFileToStorage = async (file) => {
    return new Promise((resolve, reject) => {
      this.setState({
        uploadingAttachments: [...this.state.uploadingAttachments, file],
      });
      const { draft } = this.props;
      const { teamId } = this.state;
      const storageRef = firebase.storage().ref();
      const attachmentId = uuidv4();
      const ref = storageRef.child(
        `companies/${this.props.store.signInCompany}/drafts/${
          draft.id
        }/attachments/${attachmentId}/${generateStorageAttachmentName(
          file.name
        )}`
      );

      const uploadTask = ref.put(file, {
        customMetadata: {
          teamId,
          uploader: this.props.store.me.id,
        },
      });

      message.loading({
        content: `${file.name}をアップロード中です（0%完了）`,
        key: attachmentId,
      });

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          message.loading({
            content: `${file.name}をアップロード中です（${Math.floor(
              progress
            )}%完了）`,
            key: attachmentId,
          });
        },
        (error) => {
          console.error('CreateMessage.uploadFileToStorage:', error);
          message.error({
            content: `${file.name}のアップロードに失敗しました`,
            key: attachmentId,
          });
          this.setState({
            uploadingAttachments: this.state.uploadingAttachments.filter(
              (f) => f.uid !== file.uid
            ),
          });
          reject();
        },
        async () => {
          // 成功
          file.storagePath = uploadTask.snapshot.ref.fullPath; // Fileオブジェクトのため、スプレッド演算子を使用しない
          const attachment = generateAttachment(file);
          this.setState(
            {
              attachments: [...this.getUsedAttachments(), attachment],
              uploadingAttachments: this.state.uploadingAttachments.filter(
                (f) => f.uid !== file.uid
              ),
            },
            this.updateDraft
          );
          message.success({
            content: `${file.name}のアップロードが完了しました。`,
            key: attachmentId,
            duration: 2,
          });
          logEvent(eventNames.add_attachment);
          resolve(attachment);
        }
      );
    });
  };

  onDrop = async (files) => {
    // 逐次実行したいので、for of で書いている
    for (const file of files) {
      // プレーンテキストモードじゃない状態で画像ファイルはインライン画像として、それ以外のファイルは添付ファイルとして扱う
      if (!this.state.plainTextMode && isPreviewableImageMimeType(file.type)) {
        await this.sendToServerByDrop(file);
        continue;
      }

      file.uid = rcuid(); // rc-uploadを経由していないため、uidが作られず、表示時にエラーとなる。そのため、ここでuidを手動で生成している
      await this.validateFileAndUpload(file);
    }
  };

  beforeUpload = (file) => {
    this.validateFileAndUpload(file); // beforeUploadはPromiseを返せないため、敢えてawaitしていない
    return false;
  };

  isFileSizeExceeded = (uploadSize) => {
    const attachments = this.getUsedAttachments();
    const totalSize = attachments.reduce((a, b) => a + b.size, 0);
    return !((uploadSize + totalSize) / 1024 / 1024 < 15);
  };

  validateFileAndUpload = async (file) => {
    if (this.isFileSizeExceeded(file.size)) {
      message.error('添付ファイルの合計は15MBまでです');
      return;
    }
    return await this.uploadFileToStorage(file);
  };

  getNonInlineAttachments = () =>
    extractNonInlineAttachments(this.state.attachments).map((attachment) => ({
      ...attachment,
      name: attachment.name + ` (${formatFileSize(attachment.size)})`,
    }));

  getUsedAttachments = () =>
    extractUsedAttachments(this.state.bodyHtml, this.state.attachments);

  /**
   * @param file
   * @param lateDelete {boolean}
   * @return {boolean}
   */
  onRemove = (file, lateDelete = false) => {
    this.setState({
      removingAttachments: [...this.state.removingAttachments, file],
    });

    if (lateDelete) {
      this.setState(
        {
          attachments: this.state.attachments.filter((f) => f.uid !== file.uid),
          removingAttachments: this.state.removingAttachments.filter(
            (f) => f.uid !== file.uid
          ),
          lateDeleteAttachments: [...this.state.lateDeleteAttachments, file],
        },
        this.updateDraft
      );
      logEvent(eventNames.remove_attachment);
      return false;
    }

    (async () => {
      await firebase.storage().ref().child(file.storagePath).delete();
      await this.state.editorHandle.deleteImage(file.uid);
      this.setState(
        {
          attachments: this.state.attachments.filter((f) => f.uid !== file.uid),
          removingAttachments: this.state.removingAttachments.filter(
            (f) => f.uid !== file.uid
          ),
        },
        this.updateDraft
      );
      logEvent(eventNames.remove_attachment);
    })();
    return false;
  };

  deleteLateDeleteAttachments = async () => {
    const promises = this.state.lateDeleteAttachments.map((attachment) =>
      firebase.storage().ref().child(attachment.storagePath).delete()
    );
    await Promise.allSettled(promises);
  };

  onPreview = async (attachment) => {
    if (!isPreviewableImageMimeType(attachment.type)) {
      downloadAttachment(attachment);
      return;
    }

    const { ok, url } = await fetchFromPath(attachment.storagePath);
    if (!ok) {
      message.error('画像ファイルの取得に失敗しました。');
      return;
    }

    this.setState({
      previewImage: attachment,
      previewImageUrl: url,
    });
  };

  openCc = () => {
    this.setState({ ccOpen: true });
  };

  openBcc = () => {
    this.setState({ bccOpen: true });
  };

  openSubject = () => {
    this.setState({ subjectOpen: true });
  };

  /**
   * @param {string} target
   * @param {boolean} ignoreUnsubscribes
   */
  openDatePicker = (target, ignoreUnsubscribes) => {
    this.setState({
      datePickerTarget: target,
      datePickerInitialValue: null,
      ignoreUnsubscribes,
    });
  };

  closeDatePicker = (target) => {
    this.setState({ datePickerTarget: null });
  };

  toggleTextMode = () => {
    const plainTextMode = !this.state.plainTextMode;
    this.setState(
      {
        plainTextMode,
        htmlToolbarVisible: !plainTextMode,
      },
      this.updateDraftDebounced
    );
  };

  getAttachmentDownloadURL = async (attachment) => {
    const ref = firebase.storage().ref(attachment.storagePath);
    return await ref.getDownloadURL();
  };

  onUploadImage = async (file, insertDataURL, uid = rcuid()) => {
    file.uid = uid; // rc-uploadを経由していないため、uidが作られず、表示時にエラーとなる。そのため、ここでuidを手動で生成している
    file.content_id = uid;
    const attachment = await this.validateFileAndUpload(file);
    if (!attachment) {
      // 添付ファイルのアップロードに失敗した場合
      return;
    }
    if (!insertDataURL) {
      return;
    }
    // URLを取得する
    const url = await this.getAttachmentDownloadURL(attachment);
    insertDataURL(url);
  };

  sendToServerByDrop = async (file) => {
    await this.onUploadImage(file, async (url) => {
      this.state.editorHandle.insertImage(url, file.content_id);
    });
  };

  /**
   *
   * @param file {File}
   * @return {Promise<{ src: string; contentId: string; }>}
   */
  handleEditorUploadImage = async (file) => {
    return await new Promise((resolve) => {
      this.onUploadImage(file, (url) => {
        resolve({ src: url, contentId: file.content_id });
      });
    });
  };

  /**
   * @param contentId {string}
   */
  handleInsertImage = (contentId) => {
    const attachment = this.state.lateDeleteAttachments.find(
      (attachment) => attachment.uid === contentId
    );
    if (!attachment) {
      return;
    }

    this.setState({
      lateDeleteAttachments: this.state.lateDeleteAttachments.filter(
        (a) => a.uid !== contentId
      ),
      attachments: [...this.state.attachments, attachment],
    });
  };

  /**
   * @param contentId {string}
   */
  handleDeleteImage = (contentId) => {
    this.getUsedAttachments()
      .filter((attachment) => attachment.uid === contentId)
      .forEach((attachment) => {
        this.onRemove(attachment, true);
      });
  };

  checkSignatureToolbarVisible = (status) => {
    if (status) {
      this.setState({ signatureToolbarVisible: status });
    }
  };

  moveToModal = async () => {
    if (!this.movableToModal()) return;
    await this.updateDraft();
    if (this.props.deleteCallback) this.props.deleteCallback();
    store.set(modalDraftAtom, this.props.draft);
  };

  submitting = () =>
    this.props.store.cancelableSendingMessageIds.includes(this.props.draft.id);

  onClickTopbar = (clickedTopbarItem) => {
    switch (clickedTopbarItem) {
      case TopbarItem.ShowTemplateModal:
        this.showTemplateModal();
        break;
      case TopbarItem.ShowTextCompletionModal:
        this.showTextCompletionModal();
        break;
      case TopbarItem.PositiveCompletion:
        this.createCompletion('ポジティブに返信してください');
        break;
      case TopbarItem.NegativeCompletion:
        this.createCompletion('ネガティブに返信してください');
        break;
      default:
        break;
    }
  };

  onClickToolbar = (clickedToolbarItem) => {
    switch (clickedToolbarItem) {
      case ToolbarItem.ToggleHtml:
        this.toggleHtmlToolbar();
        break;
      case ToolbarItem.ShowTemplateModal:
        this.showTemplateModal();
        break;
      case ToolbarItem.ShowUploadDialog:
        this.showUploadDialog();
        break;
      case ToolbarItem.Delete:
        this.handleDelete();
        break;
      case ToolbarItem.PlainTextMode:
        this.toggleTextMode();
        break;
      default:
        break;
    }
  };

  /**
   * @param to {string[]}
   * @return {Promise<string|null>}
   */
  fetchToAddressContactName = async (to) => {
    if (this.state.toName) {
      return this.state.toName;
    }
    if (to.length <= 0) {
      return null;
    }

    const email = extractEmail(to[0]);
    let name = await this.props.store.contactStore.getContactNameByEmail({
      teamId: this.state.teamId,
      emailAddress: email,
    });

    if (name === email) {
      const extractedName = extractName(to[0]);
      name = extractedName.length > 0 ? extractedName : email.split('@')[0];
    }

    this.setState({ toName: name });

    return name;
  };

  render() {
    const {
      inboxId,
      teamId,
      to,
      cc,
      bcc,
      subject,
      bodyHtml,
      signature,
      useQuote,
      htmlToolbarVisible,
      templateVisible,
      textCompletionVisible,
      isUpdating,
      updatedAt,
      ccOpen,
      bccOpen,
      subjectOpen,
      datePickerTarget,
      datePickerInitialValue,
      plainTextMode,
      signatureToolbarVisible,
      readOnly,
    } = this.state;
    const { store } = this.props;
    const {
      joinedTeams,
      getTeamInboxes,
      getInbox,
      isScheduledDraftSupported,
      isYaritoriAISupported,
    } = store;
    const { isFollowUpMessageSupported } = store.featureStore;
    const teamSignatures = store.getSignatures(teamId);
    const inbox = getInbox(inboxId);
    const submitting = this.submitting();

    if (submitting) {
      return (
        <Sending
          to={this.state.to}
          cc={this.state.cc}
          bcc={this.state.bcc}
          subject={this.state.subject}
          bodyHtml={this.state.bodyHtml}
          signature={this.state.signature}
          inboxId={this.state.inboxId}
          getInbox={this.props.store.getInbox}
        />
      );
    }

    const inModal =
      this.props.modalDraft && this.props.modalDraft.id === this.props.draft.id;

    const [datePickerTitle, datePickerOkText] = {
      schedule: ['送信日時を選択', '日時を指定'],
      followup: ['フォローアップ日時を選択', '日時を指定'],
    }[datePickerTarget] || ['', ''];

    const bodyContent = (
      <Form
        className="grid h-full grid-rows-[auto_auto_1fr_auto]"
        data-testid="draft"
      >
        <Header>
          <div className="flex items-start gap-4">
            {this.props.draft.isReply && (
              <MessageReply className="h-6 w-6 shrink-0" />
            )}
            <div className="w-full">
              {/* 宛先 */}
              <FromWrapper className="mb-2 flex-nowrap items-start">
                <Label className="mt-1.5 self-start">宛先:</Label>
                <div
                  className="mr-1 flex-1 rounded-lg border border-gray-300 px-1 py-0"
                  data-testid="draftTo"
                >
                  <EditableTagGroup
                    droppableId={FROM_DROPPABLE_ID}
                    droppable={!isUpdating}
                    onDragStart={this.onDragStart}
                    onDragEnd={this.onDragEnd}
                    values={to}
                    setValues={this.setTo}
                    onEnterEmptyValue={this.focusSubjectOrBody}
                    isDuplicatedRecipients={this.isDuplicatedRecipients}
                    isDuplicatedOtherRecipients={(value) =>
                      this.isDuplicatedRecipients(value, {
                        to: false,
                        cc: true,
                        bcc: true,
                      })
                    }
                    getRecipientsLength={this.getRecipientsLength}
                    teamId={teamId}
                    disabled={submitting}
                    autoFocus={this._autoFocusTo}
                  />
                </div>
                <Options className="mt-1.5 space-x-1">
                  {!ccOpen && <Option onClick={this.openCc}>Cc</Option>}
                  {!bccOpen && <Option onClick={this.openBcc}>Bcc</Option>}
                  {!subjectOpen && (
                    <Option onClick={this.openSubject}>件名</Option>
                  )}
                  {!inModal && (
                    <PCOnly>
                      <Option onClick={this.moveToModal}>
                        <ExPopUpIcon />
                      </Option>
                    </PCOnly>
                  )}
                </Options>
              </FromWrapper>

              {/* Cc */}
              <div
                style={{
                  marginBottom: 8,
                  display: ccOpen ? 'flex' : 'none',
                  alignItems: 'start',
                }}
              >
                <Label style={{ alignSelf: 'start', marginTop: '6px' }}>
                  Cc:
                </Label>
                <div
                  className="flex-1 rounded-lg border border-gray-300 px-1 py-0"
                  data-testid="draftCc"
                >
                  <EditableTagGroup
                    droppableId={CC_DROPPABLE_ID}
                    droppable={!isUpdating}
                    onDragStart={this.onDragStart}
                    onDragEnd={this.onDragEnd}
                    values={cc}
                    setValues={this.setCc}
                    onEnterEmptyValue={this.focusSubjectOrBody}
                    isDuplicatedRecipients={this.isDuplicatedRecipients}
                    isDuplicatedOtherRecipients={(value) =>
                      this.isDuplicatedRecipients(value, {
                        to: true,
                        cc: false,
                        bcc: true,
                      })
                    }
                    getRecipientsLength={this.getRecipientsLength}
                    teamId={teamId}
                    disabled={submitting}
                  />
                </div>
              </div>

              {/* Bcc */}
              <div
                style={{
                  display: bccOpen ? 'flex' : 'none',
                  alignItems: 'start',
                  marginBottom: 8,
                }}
              >
                <Label style={{ alignSelf: 'start', marginTop: '6px' }}>
                  Bcc:
                </Label>
                <div
                  className="flex-1 rounded-lg border border-gray-300 px-1 py-0"
                  data-testid="draftBcc"
                >
                  <EditableTagGroup
                    droppableId={BCC_DROPPABLE_ID}
                    droppable={!isUpdating}
                    onDragStart={this.onDragStart}
                    onDragEnd={this.onDragEnd}
                    values={bcc}
                    setValues={this.setBcc}
                    onEnterEmptyValue={this.focusSubjectOrBody}
                    isDuplicatedRecipients={this.isDuplicatedRecipients}
                    isDuplicatedOtherRecipients={(value) =>
                      this.isDuplicatedRecipients(value, {
                        to: true,
                        cc: true,
                        bcc: false,
                      })
                    }
                    getRecipientsLength={this.getRecipientsLength}
                    teamId={teamId}
                    disabled={submitting}
                  />
                </div>
              </div>

              {/* 差出人 */}
              <div className="mb-4 mr-2 flex w-full">
                <Label>差出人:</Label>
                <From
                  inboxId={inboxId}
                  teamId={teamId}
                  submitting={submitting}
                  joinedTeams={joinedTeams}
                  getTeamInboxes={getTeamInboxes}
                  inbox={inbox}
                  onChange={this.setInbox}
                  isReply={this.props.draft.isReply}
                  parseItems={this.getParseItems(to, inbox)}
                />
              </div>
            </div>
          </div>

          {/* 件名 */}
          <SubjectWrapper
            style={{
              display: subjectOpen ? 'flex' : 'none',
            }}
          >
            <Label>件名:</Label>
            <input
              data-testid="draftSubject"
              className="flex-1 text-xs leading-8 focus:border-0 focus:shadow-none focus:outline-0 focus-visible:outline-0 "
              value={subject}
              onChange={this.setSubject}
              disabled={submitting}
              placeholder=""
              onKeyDown={this.onKeyDownInSubject}
              ref={this.subjectRef}
            />
          </SubjectWrapper>
        </Header>

        <CreateMessageTopBar
          isReply={this.props.draft.isReply}
          onClickTopbar={this.onClickTopbar}
          submitting={submitting}
          isYaritoriAISupported={isYaritoriAISupported}
        />

        {/* 本文 + 署名 + 引用 */}
        <div
          className={editorWrapper({
            isReply:
              this.props.draft.isReply || this.props.draft.inReplyToSentId,
            inModal,
          })}
        >
          <SimpleBar
            id={this.state.uniqueId}
            className={'flex-1 px-4' + (inModal ? '' : ' h-[260px]')}
            classNames={{
              wrapper: 'simplebar-wrapper h-0 w-0',
              track: 'simplebar-track my-2',
              contentEl: 'simplebar-content h-full',
              contentWrapper: 'simplebar-content-wrapper outline-none h-full',
            }}
          >
            <div className="grid min-h-full grid-rows-[1fr_auto] gap-2 py-2">
              {/* 本文 */}
              <CreateMessageWysiwygEditor
                className="min-h-[100px]"
                defaultValue={bodyHtml}
                plainTextMode={plainTextMode}
                disabled={readOnly || submitting}
                initEditorHandle={(editorHandle) =>
                  this.setState({ editorHandle })
                }
                onChange={this.setBody}
                onCtrlEnter={async () => {
                  await this.handleSubmitWithNextStatus();
                }}
                onFocus={() =>
                  this.setState({ signatureToolbarVisible: false })
                }
                onEnter={this.props.onEnter}
                uploadImage={this.handleEditorUploadImage}
                onInsertImage={this.handleInsertImage}
                onDeleteImage={this.handleDeleteImage}
                autoFocus={
                  // 返信かつ転送ではないときにエディターにフォーカスさせる
                  (this.props.draft.isReply ||
                    this.props.draft.inReplyToSentRef) &&
                  !this.props.draft.isForwarded
                }
                bubbleMenuContainerId={this.state.uniqueId}
              >
                {isYaritoriAISupported && this.state.editorHandle && (
                  <EditorBubbleMenu
                    handle={this.state.editorHandle}
                    draftId={this.props.draft.id}
                    bubbleMenuContainerId={this.state.uniqueId}
                  />
                )}
              </CreateMessageWysiwygEditor>
              {/* 署名 */}
              <Signature
                readOnly={readOnly}
                teamSignatures={teamSignatures}
                signature={signature}
                submitting={submitting}
                setSignature={this.setSignature}
                setSignatureBody={this.setSignatureBody}
                removeSignature={this.removeSignature}
                addSignature={this.addSignature}
                plainTextMode={plainTextMode}
                htmlToolbarVisible={this.state.htmlToolbarVisible}
                checkSignatureToolbarVisible={this.checkSignatureToolbarVisible}
                initEditorHandle={(signatureEditorHandle) =>
                  this.setState({ signatureEditorHandle })
                }
                uploadImage={this.handleEditorUploadImage}
                bubbleMenuContainerId={this.state.uniqueId}
              />

              {/* 引用 */}
              {(this.props.draft.isReply ||
                this.props.draft.inReplyToSentRef) && (
                <Quote
                  useQuote={useQuote}
                  addQuote={this.addQuote}
                  removeQuote={this.removeQuote}
                  draft={this.props.draft}
                  plainTextMode={plainTextMode}
                />
              )}
            </div>
          </SimpleBar>
        </div>

        {plainTextMode && (
          <div className="mb-2">
            <Tag style={{ border: 'none', backgroundColor: '#F1F1F4' }}>
              プレーンテキストモード
            </Tag>
          </div>
        )}

        {/* ツールバー */}
        <div className="-mx-4 flex flex-col gap-2 border-t border-[#cccccc60] px-4 pt-2">
          <CustomUpload
            beforeUpload={this.beforeUpload}
            onRemove={this.onRemove}
            onPreview={this.onPreview}
            listType="text"
            fileList={this.getNonInlineAttachments()}
            multiple
          >
            <div ref={this.uploadFieldRef} />
          </CustomUpload>
          {htmlToolbarVisible && !signatureToolbarVisible && (
            <EditorToolbarWithControls
              handle={this.state.editorHandle}
              onClose={() => this.hideCustomToolbar()}
              onInsertImage={this.onInsertImage}
            />
          )}
          {htmlToolbarVisible && signatureToolbarVisible && (
            <EditorToolbarWithControls
              handle={this.state.signatureEditorHandle}
              onClose={() => this.hideCustomToolbar()}
            />
          )}
          <CreateMessageFooter
            draft={this.props.draft}
            onClickToolbar={this.onClickToolbar}
            submitting={submitting}
            isUpdating={isUpdating}
            updatedAt={updatedAt}
            htmlToolbarVisible={htmlToolbarVisible}
            plainTextMode={plainTextMode}
            cancelFollowup={() => this.handleFollowup(null)}
            submitButtonProps={{
              draft: this.props.draft,
              disabled: submitting || !this.canSend(),
              handleSubmit: this.handleSubmit,
              handleDatePicker: this.handleDatePicker,
              openDatePicker: this.openDatePicker,
              isScheduledDraftSupported,
              isFollowUpMessageSupported,
            }}
          />
        </div>
      </Form>
    );

    const modalElements = (
      <>
        {/* モーダル - テンプレート */}
        <TemplateModal
          templateVisible={templateVisible}
          hideTemplateModal={this.hideTemplateModal}
          showTemplateModal={this.showTemplateModal}
        >
          <Template
            teamId={teamId}
            setTemplate={this.setTemplate}
            parseReservedWords={(body) =>
              this.parseReservedWords(body, {
                overrideToName: this.state.toName,
              })
            }
            draft={{
              subject: this.state.subject,
              body: this.state.body,
              bodyHtml: this.state.bodyHtml,
            }}
          />
        </TemplateModal>
        {/* モーダル - 自動生成 */}
        <AiPromptDialog
          open={textCompletionVisible}
          onGenerate={this.createCompletion}
          onOpenChange={(open) =>
            this.setState({
              textCompletionVisible: open,
            })
          }
        />

        {!!datePickerTarget && (
          <DatePickerModal
            title={datePickerTitle}
            okText={datePickerOkText}
            initialValue={datePickerInitialValue}
            close={this.closeDatePicker}
            onChange={(datetime) =>
              this.handleDatePicker(datetime, this.state.ignoreUnsubscribes)
            }
          />
        )}

        {/* モーダル - プレビュー */}
        <PreviewImageModal
          open={this.state.previewImage && this.state.previewImageUrl}
          onClose={this.hidePreviewImageModal}
          url={this.state.previewImageUrl}
          onDownload={() => downloadAttachment(this.state.previewImage)}
        />
      </>
    );

    if (isSP()) {
      return (
        <div className={inModal ? 'mt-4 h-[calc(100vh-68px)]' : ''}>
          {bodyContent}
          {modalElements}
        </div>
      );
    }

    return (
      <div
        className="h-full"
        onDragOver={() => {
          if (!this.state.readOnly) {
            this.setState({ readOnly: true });
          }
        }}
        onDrop={() => {
          setTimeout(() => this.setState({ readOnly: false }), 100);
        }}
      >
        <Dropzone onDrop={this.onDrop} noClick noKeyboard>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div {...getRootProps({ className: 'dropzone h-full relative' })}>
              <input {...getInputProps()} />
              {isDragActive && (
                <div className="absolute left-0 top-0 flex w-full items-center justify-center bg-white/50">
                  <Result
                    icon={
                      <Icon
                        icon={NoteAdd}
                        size={32}
                        className={'text-sumi-800'}
                      />
                    }
                    subTitle="ドロップしてファイルをアップロード"
                  />
                </div>
              )}
              <DropzoneInner isDragActive={isDragActive}>
                {bodyContent}
              </DropzoneInner>
            </div>
          )}
        </Dropzone>
        {modalElements}
      </div>
    );
  }
}

const Composed = compose(withRouter, inject('store'), observer)(Index);

export default (props) => {
  const modalDraft = useAtomValue(modalDraftAtom);
  return <Composed {...props} modalDraft={modalDraft} />;
};

const DropzoneInner = styled.div`
  height: 100%;
  ${({ isDragActive }) =>
    isDragActive &&
    css`
      opacity: 0.1;
    `};
`;

const CustomUpload = styled(Upload)`
    .ant-upload-select-text {
        display: none !important;
    }

    .ant-upload-list::before,
    .ant-upload-list::after {
        content: none;
    }

    .ant-upload-list-item-name {
        cursor: pointer;
        max-width: 500px;
        ${media.lessThan('medium')`
      max-width: 300px;
    `}
`;

const Options = styled.div`
  display: flex;
`;

const Option = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  color: #8d8d8d;
  padding: 1px 2px;
  border-radius: 4px;
  white-space: nowrap;

  :hover {
    background-color: #f3f3fa;
  }
`;

const Label = styled.span`
  display: inline-block;
  width: 40px;
  flex: 0 0 40px;
  margin-right: 8px;
  white-space: nowrap;
  align-self: center;
  color: #8d8d8d;
`;

const FromWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const SubjectWrapper = styled.div`
  border-top: 1px solid #cccccc60 !important;
  border-bottom: 1px solid #cccccc60 !important;
  padding-left: 16px;
  margin: 12px -16px 4px;
`;

const ExPopUpIcon = styled(PopUpIcon)`
  padding-top: 4px;
  height: 15px;
  fill: #8d8d8d;
  cursor: pointer;
`;

const Header = styled.div`
  position: sticky;
`;
