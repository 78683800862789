import React from 'react';
import { MainNavItem } from './MainNavItem';
import {
  Help,
  Insights,
  Menu,
  Patient,
  Send,
  ServiceLogo,
  Settings,
} from '../../../components/icons';
import { NavLink, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { MainSearch } from './MainSearch';
import { Icon } from '../../../components/basics';
import { useTopPagePath } from '../../../hooks/useTopPagePath';
import { useStore } from 'hooks/useStore';
import { PCOnly, TabletOnly } from '../MediaQuery';
import { MobileNavButton } from '../../Top/MobileNavButton';
import { MainNavTopButton } from './MainNavTopButton';
import { useAtomValue } from 'jotai';
import { companyAtom } from '../../../atoms/auth';
import { Tooltip } from '../../../components/basics/Tooltip/Tooltip';

export const MainNav = observer(() => {
  const location = useLocation();
  const store = useStore();
  const topPagePath = useTopPagePath(store);
  const handleClickChannelItem = () => {
    store.channelStore.showMessenger();
  };
  const { isDeliveryEnabled } = useAtomValue(companyAtom);

  const pathname = location.pathname;
  const shouldShowSearchInput =
    pathname.startsWith('/me/') ||
    pathname.startsWith('/teams/') ||
    pathname.startsWith('/search/');

  return (
    <div className="relative flex h-header items-center xl:justify-center">
      <PCOnly>
        <div className="flex items-center xl:absolute xl:left-0">
          <div className="flex items-center gap-6">
            <div className="mx-4">
              <NavLink
                to={topPagePath}
                className="flex h-full w-full items-center justify-center"
                data-testid="main-nav-icon"
              >
                <Icon icon={ServiceLogo} className="text-sea-500" />
              </NavLink>
            </div>
            <MainNavTopButton />
            <MainNavItem to="/reports">
              <Icon icon={Insights} className="text-sumi-900" />
            </MainNavItem>
            <MainNavItem to="/contacts">
              <Icon
                aria-label="contacts-route"
                icon={Patient}
                className="font-bold text-sumi-900"
              />
            </MainNavItem>
            <div className="flex items-center gap-1">
              <Tooltip
                className="max-w-[300px]"
                content={
                  <>
                    メール配信機能（α）をリリースしました。
                    <br />
                    順番にご案内しておりますのでご興味のある方は、右上のチャットからお問い合わせください。
                  </>
                }
              >
                <MainNavItem to={isDeliveryEnabled ? '/delivery' : undefined}>
                  <Icon icon={Send} className="font-bold text-sumi-900" />
                </MainNavItem>
              </Tooltip>
              <div className="relative z-[-1] flex h-6 select-none items-center rounded-lg bg-[#def8d9] px-1.5 font-bold text-[#2ec015]">
                NEW
              </div>
            </div>
          </div>
        </div>
      </PCOnly>
      <TabletOnly>
        <div className="pl-4">
          <MobileNavButton icon={Menu} onClick={store.openDrawer} />
        </div>
      </TabletOnly>
      <div className="h-10 flex-1 px-4 xl:w-1/2 xl:flex-none">
        {shouldShowSearchInput && <MainSearch />}
      </div>
      <div className="mr-4 flex items-center gap-3 xl:absolute xl:right-0">
        {store.channelStore.canShowChannel(location.pathname) ? (
          <MainNavItem
            onClick={handleClickChannelItem}
            badge={store.channelStore.unreadCount}
          >
            <Icon icon={Help} className="text-sumi-900" />
          </MainNavItem>
        ) : null}
        <PCOnly>
          <MainNavItem to="/settings" label="設定">
            <Icon icon={Settings} className="text-sumi-900" />
          </MainNavItem>
        </PCOnly>
      </div>
    </div>
  );
});
