import {
  DocumentSnapshot,
  FieldPath,
  getDocs,
  onSnapshot,
  query,
  Query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import _ from 'lodash';

const CHUNK_SIZE = 10;

/**
 * Because `in` operator has 10 elements limit, split arr to send multiple
 * queries.
 */
export const fetchQueryInArray = async <T>(
  q: Query<T>,
  field: string | FieldPath,
  arr: unknown[]
): Promise<QueryDocumentSnapshot<T>[]> => {
  const docs = await Promise.all(
    _.chunk(arr, CHUNK_SIZE).map(
      async (x) => (await getDocs<T>(query(q, where(field, 'in', x)))).docs
    )
  );
  return docs.flat();
};

/**
 * Because `in` operator has 10 elements limit, split arr to send multiple
 * queries.
 */
export const subscribeQueryInArray = <T>(
  q: Query<T>,
  field: string | FieldPath,
  arr: unknown[],
  onNext: (snap: QuerySnapshot<T>, docs: DocumentSnapshot<T>[]) => void,
  onAllLoaded?: () => void
): Unsubscribe => {
  const loaded: boolean[] = [];
  const docs: DocumentSnapshot<T>[][] = [];

  const unsubscribeFunctions: Unsubscribe[] = _.chunk(arr, CHUNK_SIZE).map(
    (chunk, i) => {
      loaded[i] = false;
      return onSnapshot<T>(
        query<T>(q, where(field, 'in', chunk)),
        (snap) => {
          docs[i] = snap.docs;
          onNext(snap, docs.flat());
          if (!loaded[i]) {
            loaded[i] = true;
            if (loaded.every(Boolean)) {
              onAllLoaded?.();
            }
          }
        },
        (err) => console.error(err)
      );
    }
  );

  return () => {
    unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
  };
};
