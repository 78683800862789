import { Avatar } from '../../../components/basics/Avatar/Avatar';
import React, { ComponentProps, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Tooltip } from '../../../components/basics/Tooltip/Tooltip';
import Linkify from 'react-linkify';
import reactStringReplace from 'react-string-replace';
import { escapeRegExp } from 'lodash';
import { tv } from 'tailwind-variants';
import { Button, Icon, IconButton } from '../../../components/basics';
import {
  AddReaction,
  ArrowDownward,
  Delete,
  Edit,
} from '../../../components/icons';
import { twMerge } from 'tailwind-merge';
import Picker from '@emoji-mart/react';
import * as Popover from '@radix-ui/react-popover';
import data from '@emoji-mart/data/sets/15/google.json';
import i18n from '@emoji-mart/data/i18n/ja.json';
import { Emoji } from '../../../components/basics/Emoji/Emoji';
import { Count } from '../../../components/basics/Count/Count';
import { Emoji as EmojiMartEmoji } from '@emoji-mart/data';
import { Dialog } from '../../../components/basics/dialog/Dialog';
import { DialogHeader } from '../../../components/basics/dialog/DialogHeader';
import { DialogFooter } from '../../../components/basics/dialog/DialogFooter';
import { DialogContent } from '../../../components/basics/dialog/DialogContent';
import * as RadixDialog from '@radix-ui/react-dialog';
import { useConfirmDialog } from '../../../hooks/confirmDialog';
import TextareaAutosize from 'react-textarea-autosize';

type Attachment = {
  id: string;
  name: string;
};

type Props = {
  id: string;
  user: NonNullable<ComponentProps<typeof Avatar>['user']>;
  content: string;
  reply?: {
    name: string;
    content: string;
  };
  reactions?: Record<string, { id: string; name: string }[]>;
  timestamp: Date;
  mentionTargets: string[];
  attachments?: Attachment[];
  onRemoveAttachment?: (attachmentId: string) => Promise<void>;
  onDownloadAttachment?: (attachmentId: string) => void;
  currentUser?: {
    id: string;
    name: string;
  };
  highlight?: boolean;
  onToggleEmoji: ((emoji: string) => void) | undefined;
  onEditContent: ((content: string) => Promise<void>) | undefined;
  onDelete: (() => Promise<boolean>) | undefined;
  reactionSupportStatus: 'loading' | 'supported' | 'unsupported';
  isAuthor: boolean;
  readonly?: boolean;
  isEdited?: boolean;
};

const wrapper = tv({
  base: 'relative z-[1] grid w-full grid-cols-[auto_1fr] flex-col items-start gap-2 text-sumi-900',
  variants: {
    highlight: {
      true: twMerge(
        "before:pointer-events-none before:absolute before:-inset-2 before:z-[-1] before:rounded-[calc(theme(borderRadius.lg)_+_theme(spacing.2)_/_2)] before:bg-sea-100 before:content-['']",
        'before:animate-comment-highlight'
      ),
    },
    deleting: {
      true: 'opacity-50',
    },
  },
});

const bubble = tv({
  base: 'w-fit whitespace-pre-line break-all rounded-lg rounded-tl-md bg-white px-2 py-1.5 text-sm leading-6',
});

const toolbarButton = tv({
  base: 'm-0 flex h-7 w-7 cursor-pointer items-center justify-center rounded-lg bg-transparent p-0 enabled:hover:bg-sumi-100 disabled:opacity-50',
});

const reaction = tv({
  base: 'm-0 flex h-7 select-none items-center gap-1 rounded-lg border border-sumi-100 bg-white p-0 px-1.5 text-sm text-sumi-600',
  variants: {
    active: {
      true: 'border-sea-100 bg-sea-50',
    },
    readonly: {
      true: 'cursor-default',
      false: 'cursor-pointer hover:border-sumi-300',
    },
  },
  compoundVariants: [
    {
      active: true,
      readonly: false,
      className: 'hover:border-sea-200',
    },
  ],
  defaultVariants: {
    readonly: false,
  },
});

export const Comment = ({
  id,
  user,
  content,
  reply,
  reactions = {},
  timestamp,
  mentionTargets,
  currentUser,
  highlight = false,
  attachments = [],
  onRemoveAttachment,
  onDownloadAttachment,
  onToggleEmoji,
  onEditContent,
  onDelete,
  reactionSupportStatus,
  isAuthor = false,
  readonly = false,
  isEdited = false,
}: Props) => {
  const editTextareaRef = useRef<HTMLTextAreaElement>(null);
  const [toolbarEmojiPickerOpen, setToolbarEmojiPickerOpen] = useState(false);
  const [inlineEmojiPickerOpen, setInlineEmojiPickerOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editingContent, setEditingContent] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const confirm = useConfirmDialog();
  const date = useMemo(() => {
    const date = moment(timestamp);
    return (
      <Tooltip content={date.format('YYYY年M月D日 HH:mm')}>
        <div className="text-sumi-600">{date.fromNow()}</div>
      </Tooltip>
    );
  }, [timestamp]);
  const replyComment = useMemo(() => {
    if (!reply) {
      return null;
    }
    return (
      <div className="flex flex-col gap-2">
        <div className="text-xs text-sumi-600">
          {reply.name}さんに返信しました
        </div>
        <div className="grid grid-cols-[auto_1fr] gap-1">
          <div className="h-full w-[4px] rounded-full bg-sumi-300" />
          <div className={bubble({ className: 'text-xs' })}>
            {highlightMentions(
              reply.content,
              mentionTargets,
              currentUser?.name
            )}
          </div>
        </div>
      </div>
    );
  }, [reply]);
  const picker = useMemo(
    () => (
      <Picker
        data={data}
        i18n={i18n}
        theme="light"
        set="google"
        onEmojiSelect={(
          emoji: EmojiMartEmoji & { shortcodes: string },
          e: PointerEvent
        ) => {
          onToggleEmoji?.(emoji.shortcodes);
          if (!e.shiftKey) {
            setToolbarEmojiPickerOpen(false);
            setInlineEmojiPickerOpen(false);
          }
        }}
      />
    ),
    [onToggleEmoji]
  );
  const comment = useMemo(() => {
    return (
      <Linkify componentDecorator={componentDecorator}>
        {highlightMentions(content, mentionTargets, currentUser?.name)}
      </Linkify>
    );
  }, [content, mentionTargets, currentUser?.name]);
  const reactionEntries = Object.entries(reactions)
    .map((e) => ({ emoji: e[0], count: e[1].length, users: e[1] }))
    .sort((a, b) => b.count - a.count);

  const handleDelete = () => {
    setShowDeleteDialog(false);
    if (!onDelete) {
      return;
    }
    setDeleting(true);
    (async () => {
      try {
        if (await onDelete()) {
          return;
        }
        setDeleting(false);
      } catch (e) {
        console.error(e);
        setDeleting(false);
      }
    })();
  };

  const handleRemoveAttachment = (attachment: Attachment) => {
    confirm({
      title: `添付ファイルを削除しますか？`,
      description: (
        <>
          "{attachment.name}" を削除しますか？
          <br />
          一度削除すると元に戻せません
        </>
      ),
      okText: '削除',
      okType: 'danger',
      onOk: async () => onRemoveAttachment?.(attachment.id),
    });
  };

  return (
    <>
      <div
        className={wrapper({ highlight, deleting })}
        {...({ name: `comment-${id}` } as any)}
      >
        <Avatar size={36} user={user} />
        <div className="flex w-full flex-col gap-1">
          {replyComment}
          <div className="flex items-center gap-4 text-xs">
            <div className="font-bold">{user.name}</div>
            {date}
            {isEdited && <div className="text-sumi-500">(編集済み)</div>}
          </div>
          <div
            className={twMerge(
              'group relative min-w-[80px] pr-8',
              editing ? 'w-full' : 'w-fit'
            )}
          >
            {editing ? (
              <form
                action=""
                onSubmit={async (e) => {
                  e.preventDefault();
                  setEditLoading(true);
                  try {
                    await onEditContent?.(editingContent);
                    setEditing(false);
                  } catch (e) {
                    console.error(e);
                  } finally {
                    setEditLoading(false);
                  }
                }}
                className="grid w-full grid-cols-[1fr_auto_auto] items-end gap-2"
              >
                <TextareaAutosize
                  className="h-9 resize-none rounded-lg border border-sumi-300 px-2 py-1.5 text-sm leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-sea-200"
                  value={editingContent}
                  onChange={(e) => setEditingContent(e.target.value)}
                  ref={editTextareaRef}
                />
                <Button
                  color="primary"
                  variant="text"
                  className="h-9"
                  onClick={() => setEditing(false)}
                  disabled={editLoading}
                  data-testid="cancel-edit-comment"
                >
                  キャンセル
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  className="h-9"
                  loading={editLoading}
                  data-testid="save-edit-comment"
                >
                  保存
                </Button>
              </form>
            ) : (
              <div data-testid="comment-container" className={bubble()}>
                {comment}
              </div>
            )}
            {!editing && !deleting && !readonly && (
              <div
                data-testid="comment-options"
                className="absolute -top-9 right-0 max-w-0"
              >
                <div className="invisible absolute -right-4 -top-2 p-4 pt-2 hover:visible group-hover:visible">
                  <div
                    className={twMerge(
                      'flex w-fit flex-nowrap gap-1 rounded-lg border border-sumi-300 bg-white p-1 text-sumi-600',
                      'shadow-comment-toolbar',
                      toolbarEmojiPickerOpen ? 'visible' : ''
                    )}
                  >
                    <Popover.Root
                      open={toolbarEmojiPickerOpen}
                      onOpenChange={setToolbarEmojiPickerOpen}
                    >
                      <Tooltip
                        content={
                          reactionSupportStatus === 'supported'
                            ? 'リアクション'
                            : 'ビジネスプランでリアクションを利用できます'
                        }
                        visible={reactionSupportStatus !== 'loading'}
                      >
                        <Popover.Trigger asChild>
                          <button
                            aria-label="リアクションをつける"
                            type="button"
                            className={toolbarButton()}
                            disabled={reactionSupportStatus !== 'supported'}
                          >
                            <Icon icon={AddReaction} size={20} />
                          </button>
                        </Popover.Trigger>
                      </Tooltip>
                      <Popover.Portal>
                        <Popover.Content
                          align="start"
                          alignOffset={-4}
                          sideOffset={6}
                        >
                          {picker}
                        </Popover.Content>
                      </Popover.Portal>
                    </Popover.Root>
                    {/* 後で実装する用 */}
                    {/*<Tooltip content="返信">*/}
                    {/*  <button*/}
                    {/*    aria-label="コメントに返信する"*/}
                    {/*    type="button"*/}
                    {/*    className={toolbarButton()}*/}
                    {/*  >*/}
                    {/*    <Icon icon={MessageReply} size={20} />*/}
                    {/*  </button>*/}
                    {/*</Tooltip>*/}
                    {isAuthor && (
                      <>
                        <Tooltip content="編集">
                          <button
                            aria-label="コメントを編集する"
                            type="button"
                            data-testid="edit-comment"
                            className={toolbarButton()}
                            onClick={() => {
                              setEditing(true);
                              setEditingContent(content);
                              setTimeout(() => {
                                const textarea = editTextareaRef.current;
                                if (!textarea) {
                                  return;
                                }
                                textarea.focus();
                                textarea.setSelectionRange(
                                  textarea.value.length,
                                  textarea.value.length
                                );
                              });
                            }}
                          >
                            <Icon icon={Edit} size={20} />
                          </button>
                        </Tooltip>
                        <Tooltip content="削除">
                          <button
                            aria-label="コメントを削除する"
                            type="button"
                            data-testid="delete-comment"
                            className={toolbarButton({
                              className: 'hover:text-sun-500',
                            })}
                            onClick={() => setShowDeleteDialog(true)}
                          >
                            <Icon icon={Delete} size={20} />
                          </button>
                        </Tooltip>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {reactionEntries.length > 0 && (
            <div className="flex flex-wrap gap-1">
              {reactionEntries.map((e) => (
                <Tooltip
                  key={e.emoji}
                  content={
                    <div className="flex flex-col items-center gap-2">
                      <div className="flex h-[48px] w-[48px] items-center justify-center rounded-lg bg-white">
                        <Emoji size={36} shortcodes={e.emoji} />
                      </div>
                      <div className="break-all text-xs">
                        {e.users
                          .map((u) => u.name)
                          .sort((a, b) => a.localeCompare(b, 'ja'))
                          .join(', ')}
                        <span className="text-sumi-500"> さんが</span>
                        <span> {e.emoji} </span>
                        <span className="text-sumi-500">
                          でリアクションしました
                        </span>
                      </div>
                    </div>
                  }
                  className="rounded-lg p-2"
                  delayDuration={500}
                >
                  <button
                    type="button"
                    className={reaction({
                      active:
                        currentUser &&
                        e.users.some((u) => u.id === currentUser.id),
                      readonly:
                        readonly || reactionSupportStatus !== 'supported',
                    })}
                    onClick={() =>
                      !readonly && !deleting && onToggleEmoji?.(e.emoji)
                    }
                    disabled={readonly || reactionSupportStatus !== 'supported'}
                    data-reaction={e.emoji}
                  >
                    <Emoji size={18} shortcodes={e.emoji} />
                    <Count value={e.count} />
                  </button>
                </Tooltip>
              ))}
              {!readonly && reactionSupportStatus === 'supported' && (
                <Popover.Root
                  open={inlineEmojiPickerOpen}
                  onOpenChange={setInlineEmojiPickerOpen}
                >
                  <Tooltip content="リアクションする">
                    <Popover.Trigger asChild>
                      <button
                        type="button"
                        className={reaction({
                          active: false,
                        })}
                        disabled={deleting}
                      >
                        <Icon icon={AddReaction} size={18} />
                      </button>
                    </Popover.Trigger>
                  </Tooltip>
                  <Popover.Portal>
                    <Popover.Content align="start" side="right" sideOffset={6}>
                      {picker}
                    </Popover.Content>
                  </Popover.Portal>
                </Popover.Root>
              )}
            </div>
          )}
          {attachments && (
            <div className="grid w-full grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
              {attachments?.map((attachment, i) => (
                <div
                  key={i}
                  className="grid grid-cols-[1fr_auto] items-center gap-1 rounded border border-sumi-200 bg-white p-1 pl-2 text-sm"
                >
                  <div className="truncate text-xs" title={attachment.name}>
                    {attachment.name}
                  </div>
                  <div className="flex gap-[1px]">
                    {onDownloadAttachment && (
                      <Tooltip content="ダウンロード">
                        <IconButton
                          aria-label="添付ファイルをダウンロード"
                          component={ArrowDownward}
                          className="text-sumi-500"
                          onClick={() => onDownloadAttachment(attachment.id)}
                        />
                      </Tooltip>
                    )}
                    {isAuthor && (
                      <Tooltip content="削除">
                        <IconButton
                          aria-label="添付ファイルを削除"
                          component={Delete}
                          className="text-sumi-500"
                          onClick={() => handleRemoveAttachment(attachment)}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DialogHeader title="コメントを削除しますか？" />
        <DialogContent className="flex flex-col gap-4">
          <div>
            一度削除すると元に戻せません。他の人も確認できなくなります。
          </div>
          <div className="rounded-lg border border-sumi-200 bg-sumi-50 p-4">
            <Comment
              id={id}
              user={user}
              content={content}
              timestamp={timestamp}
              reply={reply}
              reactions={reactions}
              mentionTargets={mentionTargets}
              currentUser={currentUser}
              onToggleEmoji={undefined}
              onEditContent={undefined}
              onDelete={undefined}
              isAuthor={false}
              reactionSupportStatus={reactionSupportStatus}
              readonly
            />
          </div>
        </DialogContent>
        <DialogFooter>
          <RadixDialog.Close asChild>
            <Button variant="outlined">キャンセル</Button>
          </RadixDialog.Close>
          <Button
            data-testid="delete-comment-dialog-button"
            color="danger"
            onClick={() => handleDelete()}
          >
            削除
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

const componentDecorator = (href: string, text: string, key: number) => (
  <a
    href={href}
    key={key}
    className="text-sea-500 hover:underline"
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
  </a>
);

export const highlightMentions = (
  text: string,
  targets: string[],
  me: string | undefined
) => {
  const otherMentions = [
    '@all',
    ...targets.filter((t) => t !== me).map((t) => '@' + escapeRegExp(t)),
  ].join('|');
  const regex = new RegExp(`(${otherMentions})`, 'g');
  let replaced = reactStringReplace(text, regex, (match, i) => (
    <span className="font-bold text-sea-500" key={i}>
      {match}
    </span>
  ));

  if (me) {
    replaced = reactStringReplace(
      replaced,
      new RegExp(`(@${escapeRegExp(me)})`, 'g'),
      (match, i) => (
        <span className="font-bold text-[#f19023]" key={i}>
          {match}
        </span>
      )
    );
  }

  return replaced;
};
