import { observer } from 'mobx-react';
import { useCollapseState } from './hooks/useCollapseState';
import { Icon } from '../../../components/basics';
import React, { ReactNode, useMemo } from 'react';
import { useStore } from '../../../hooks/useStore';
import { sumBy } from 'lodash';
import { Forum, Plus } from '../../../components/icons';
import { LeftSidebarLink } from './button/LeftSidebarLink';
import { SidebarSectionTitle } from '../../../components/sidebar/SidebarSectionTitle/SidebarSectionTitle';

type Props = {
  onNewRoom: () => void;
};

export const LeftSidebarChatRooms = observer(
  ({ onNewRoom }: Props): JSX.Element => {
    const store = useStore();
    const isReadOnly = store.me.isReadOnly;
    const [chatOpen, setChatOpen] = useCollapseState('room', false);

    const userId = store.me.id;
    const allNotificationCount = useMemo(
      () => sumBy(store.rooms, (r) => r.unreadCount(userId)),
      [store.rooms]
    );

    const title = (
      <SidebarSectionTitle
        onOpenChange={setChatOpen}
        open={chatOpen}
        rightButton={
          isReadOnly
            ? undefined
            : {
                icon: <Icon icon={Plus} size={15} className={'text-sea-500'} />,
                onClick: onNewRoom,
              }
        }
        count={!chatOpen ? allNotificationCount : undefined}
      >
        チャット
      </SidebarSectionTitle>
    );

    let rooms: ReactNode;
    if (!chatOpen) {
      rooms = null;
    } else if (store.rooms.length <= 0) {
      rooms = (
        <p className={'rounded-lg bg-sumi-100 p-2 text-xs'}>
          他のユーザーがいる場合、チャットが表示されます
        </p>
      );
    } else {
      const userId = store.me.id;
      rooms = store.rooms.map((r) => {
        const label = r.members
          .filter((m) => userId !== m)
          .map((m) => store.getUser(m)?.name || '削除されたユーザー')
          .join(', ');
        return (
          <LeftSidebarLink
            key={r.id}
            icon={Forum}
            label={label}
            count={r.unreadCount(userId)}
            to={`/rooms/${r.id}`}
          />
        );
      });
    }
    return (
      <>
        {title}
        {rooms}
      </>
    );
  }
);
