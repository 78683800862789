import React from 'react';
import { useParams } from 'react-router-dom';

import { useStore } from 'hooks/useStore';
import Conversation from './Conversation';
import { ThreadConversation } from './Conversation/ThreadConversation';
import { useSearchParams } from 'hooks/useSearchParams';
import { observer } from 'mobx-react';

const Index = observer(() => {
  const store = useStore();
  const { messageId, commentId } = useParams<{
    messageId: string;
    commentId: string;
  }>();
  const searchParams = useSearchParams();
  if (store.preferencesLoading) {
    return null;
  }

  return searchParams.get('view') === 'thread' ? (
    <ThreadConversation
      threadId={messageId}
      commentId={commentId}
      key={messageId}
    />
  ) : (
    <Conversation messageId={messageId} commentId={commentId} key={messageId} />
  );
});

export default Index;
