import React, { useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import level from '../../../../../../font/level';
import LatestComment from './LatestComment';
import { Badge, Checkbox, Tag, Tooltip } from 'antd';
import { getTagLabel } from '../../../../../../util';
import {
  isUnread,
  isUnreadComment,
  Message as MessageEntity,
  MessageLike,
  User as UserEntity,
} from 'lib';
import Assignee from './assigee';
import { ReactComponent as ExternalIcon } from '../../../../../../assets/icons/external.svg';
import { isSP } from '../../../../../../shared/util';
import { Contacts } from './contact';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { ReactComponent as AutoRepliedIcon } from '../../../../../../assets/icons/auto_replied.svg';
import { MessageItemTag, MessageItemWrapper } from 'components/MessageList';
import { MessageStar } from './MessageStar';
import { twMerge } from 'tailwind-merge';
import { useStore } from '../../../../../../hooks/useStore';
import { ImpressionIcon } from './ImpressionIcon';
import { SearchMessage } from '../../../../../../store/search';
import { useAtomValue } from 'jotai';
import { statusesAtomFamily } from 'atoms/firestore/customStatuses';
import { buildFrontMessageStatus } from 'utils/frontMessageStatus';
import { Attach } from 'components/icons';

const getStatus = (status: string | null, deleted: boolean) => {
  if (deleted) {
    return (
      <MessageItemTag className="bg-[#fafafa] text-[#d9d9d9]">
        <DeleteOutlined />
      </MessageItemTag>
    );
  }

  if (!status) {
    return (
      <MessageItemTag className="bg-[#fafafa] text-[#d9d9d9]">
        削除済み
      </MessageItemTag>
    );
  }

  switch (status) {
    case '未対応':
      return (
        <MessageItemTag className="bg-sun-50 text-sun-600">
          未対応
        </MessageItemTag>
      );
    case '対応済み':
      return (
        <MessageItemTag className="bg-sea-50 text-sea-600">
          対応済み
        </MessageItemTag>
      );
    default:
      return (
        <MessageItemTag className="bg-[#7a7a7a] text-white">
          {status}
        </MessageItemTag>
      );
  }
};

type Props = {
  message: MessageLike | SearchMessage;
  comment?: any;
  checkMessage: any;
  uncheckMessage: any;
  checkedMessages: any[];
  to: string;
  onClick: () => void;
  me: UserEntity;
  getTag: any;
  checkDisabled?: boolean;
  activeOnlyWhenExact?: boolean;
  showOpenInTab?: boolean;
  hideInboxTag?: boolean;
  hideStatusTag?: boolean;
  isReadOnly?: boolean;
};

const isMessageEntity = (message: any): message is MessageEntity => {
  return typeof message.snippet !== 'undefined';
};

const Message = ({
  message,
  comment,
  checkMessage,
  checkDisabled,
  uncheckMessage,
  checkedMessages,
  to,
  onClick,
  activeOnlyWhenExact,
  me,
  getTag,
  showOpenInTab,
  hideInboxTag,
  hideStatusTag,
  isReadOnly = false,
}: Props) => {
  const [hover, setHover] = useState(false);

  const checked = checkedMessages.some((m) => m.id === message.id);
  const location = useLocation();
  const match = useMemo(
    () =>
      matchPath(decodeURI(location.pathname), {
        path: decodeURI(new URL(to, window.location.origin).pathname),
        exact: activeOnlyWhenExact,
      }),
    [location.pathname, to, activeOnlyWhenExact]
  );

  const store = useStore();

  const onClickCheckboxWrapper: React.MouseEventHandler<HTMLDivElement> = (
    e
  ) => {
    e.stopPropagation();
    if (checked) {
      uncheckMessage(message);
      return;
    }
    checkMessage(message);
  };
  const [, customStatusesByTeam] = useAtomValue(
    statusesAtomFamily(message.teamId)
  );

  const showCheckbox =
    !isReadOnly && !checkDisabled && (hover || checkedMessages.length > 0);
  const unread = isUnread(message, me?.id);
  const commentUnread = comment && isUnreadComment(message, comment, me?.id);
  const filteredTags = message.tags
    .map((t) => getTag(t))
    .filter((t) => t && !(t?.isInbox && hideInboxTag));
  const hasNonRelatedAttachments =
    message.attachments?.filter((a) => !a.related).length > 0;
  const showLabels = !hideStatusTag || filteredTags.length > 0;
  const latestCommentCreated = Number(
    message.latestComment?.createdAt?.seconds
  );
  const lastMessageDate = Date.parse(message.date.toString()) / 1000;
  const hideLatestComment = !!(
    lastMessageDate && latestCommentCreated < lastMessageDate
  );

  const showComplaintAlert =
    store.isImpressionClassificationSupported &&
    store.company.alertComplaintEmail;

  const statusName = useMemo(
    () =>
      buildFrontMessageStatus(message.status, customStatusesByTeam).statusName,
    [message, customStatusesByTeam]
  );

  return (
    <MessageItemWrapper
      onClick={onClick}
      selected={!!match}
      onMouseEnter={() => !isSP() && setHover(true)}
      onMouseLeave={() => !isSP() && setHover(false)}
      className={twMerge(
        'group',
        showComplaintAlert && message.impression === 'complaint' && 'bg-sun-50'
      )}
    >
      {message.isAutoReplied && (
        <AutoRepliedWrapper>
          <AutoRepliedIcon />
        </AutoRepliedWrapper>
      )}
      {showOpenInTab && (
        <div className="absolute right-2">
          <ExternalIcon className="w-4" />
        </div>
      )}
      <div>
        {showLabels && (
          <div className="flex flex-wrap gap-1 pb-1 pl-5">
            {!hideStatusTag && getStatus(statusName, message.deleted)}
            {filteredTags.map((t) => {
              const parentTag = t.parentTagId
                ? getTag(t.parentTagId)
                : undefined;
              const { shortLabel, label } = getTagLabel(t.name, {
                parentTagLabel: parentTag?.name,
              });
              const tagElem = (
                <ExTag color={t.color} key={t.id}>
                  {shortLabel ?? label}
                </ExTag>
              );
              return shortLabel ? (
                <Tooltip title={label} key={t.id}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
          </div>
        )}
        <div className="flex">
          <div className="flex-auto overflow-hidden pl-5">
            <div className="relative mb-1 flex justify-between">
              {!showCheckbox && (unread || commentUnread) && (
                <div className="absolute left-[-18px] ">
                  <Unread>
                    <Badge color="blue" />
                  </Unread>
                </div>
              )}

              {showCheckbox && (
                <div className="absolute -left-5 ">
                  <div
                    className="flex w-4 justify-center"
                    onClick={onClickCheckboxWrapper}
                  >
                    <Checkbox checked={checked} />
                  </div>
                </div>
              )}
              <div className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold">
                {hasNonRelatedAttachments && (
                  <Attach
                    data-testid="attatchment-icon"
                    className="mr-2 align-sub"
                  />
                )}
                {message.from?.value?.[0] && (
                  <Contacts
                    teamId={message.teamId}
                    emails={message.from.value}
                  />
                )}
              </div>
              <div className="ml-2 mt-1 whitespace-pre text-xs text-sumi-600">
                {message.date.isSame(moment(), 'day')
                  ? message.date.format('HH:mm')
                  : message.date.format('M月D日')}
              </div>
            </div>

            <div className="mb-1 flex h-[18px] items-center gap-1 overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium text-sumi-900">
              {showComplaintAlert && (
                <ImpressionIcon impression={message.impression} />
              )}
              {message.subject || '（件名がありません）'}
            </div>
            <div className="mb-1 overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium text-sumi-600">
              {(isMessageEntity(message) ? message.snippet : message.text) ||
                '（本文がありません）'}
            </div>
            {!comment && hideLatestComment ? (
              <></>
            ) : (
              <LatestComment message={message} comment={comment} />
            )}
          </div>
          <div className="ml-2 w-6 flex-none">
            <Assignee assignee={message.assignee} />
            <div className="mt-1.5 flex h-4 justify-center">
              {!isReadOnly && (
                <MessageStar
                  message={message}
                  notStarredClassName="lg:opacity-0 lg:group-hover:opacity-100"
                  starredClassName={'opacity-100'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </MessageItemWrapper>
  );
};

const AutoRepliedWrapper = styled.div`
  margin-bottom: 12px;
  position: absolute;
  left: 7px;
  top: 12px;

  & > svg {
    width: 14px;
    height: 14px;

    * {
      fill: #fc9a33;
    }
  }
`;

const Unread = styled.div`
  & > .ant-badge > .ant-badge-status-dot {
    width: 8px;
    height: 8px;
  }
`;

const ExTag = styled(Tag)`
  font-size: ${level[0]} !important;
  margin: 0 !important;
  border-radius: 8px !important;
`;

export default Message;
