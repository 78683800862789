import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Avatar from '../../Common/Avatar';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import level from '../../../font/level';
import media from 'styled-media-query';
import Linkify from 'react-linkify';
import moment from 'moment';

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

class Chat extends Component {
  render() {
    const { chat } = this.props;
    const creator = this.props.store.getUser(chat.creator);

    const isBeforeToday = chat.date
      .clone()
      .startOf('day')
      .isBefore(moment().startOf('day'));

    return (
      <Wrapper name={`chat-${chat.id}`}>
        <AvatarWrapper>
          <Avatar user={creator} />
        </AvatarWrapper>
        <Body>
          <Meta>
            <Commenter>{creator ? creator.name : '削除されたユーザ'}</Commenter>
            <CommentedAt>
              {isBeforeToday
                ? chat.date.format('M/D HH:mm')
                : chat.date.format('HH:mm')}
            </CommentedAt>
          </Meta>
          <Comment>
            <Text>
              <Linkify componentDecorator={componentDecorator}>
                {chat.text}
              </Linkify>
            </Text>
          </Comment>
        </Body>
      </Wrapper>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Chat);

const Wrapper = styled.div`
  display: flex;
  padding: 10px 20px;
  border-radius: 8px;
  ${media.greaterThan('small')`
    margin: 14px;
    padding: 0px 6px;
  `}
`;

const AvatarWrapper = styled.div`
  margin-top: 12px;
`;

const Body = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const Meta = styled.div`
  display: flex;
  align-items: center;
`;

const Commenter = styled.span`
  font-weight: bold;
  padding-right: 5px;
`;

const CommentedAt = styled.span`
  font-size: ${level[0]};
  color: #8d8d8d;
`;

const Comment = styled.div`
  width: fit-content;
  border-radius: 30px;
  background-color: #efeeee;
  padding: 8px 15px;
`;

const Text = styled.div`
  white-space: pre-line;
  word-break: break-all;
`;
