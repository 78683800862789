import React, { useEffect } from 'react';
import { MyEmailTabs } from './MyEmailTabs';
import { useLocation } from 'react-router-dom';
import { MyEmailRoutes } from './MyEmailRoutes';
import { Store } from '../../../../store';
import { observer } from 'mobx-react';
import _ from 'lodash';

type Props = {
  routes: MyEmailRoutes;
  store: Store;
};

export const MyEmail: React.FC<Props> = observer((props: Props) => {
  const location = useLocation();
  const { routes, store } = props;

  useEffect(() => {
    routes.redirectToInboxIfNeeded(location.pathname);
  }, [location, routes]);

  const teamId = store.privateTeam?.id;
  if (!teamId) {
    return <></>;
  }

  const inbox = _.first(store.getTeamInboxes(teamId));

  return <MyEmailTabs title={`${inbox?.email}の設定`} routes={routes} />;
});
