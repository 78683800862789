import { companyAtom, companyLoadingAtom } from 'atoms/auth';
import { atom, useAtomValue } from 'jotai';
import { db9 } from '../../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { atomEffect } from 'jotai-effect';

type StripeProduct = {
  id: string;
  name: string;
  maxInboxes: number;
  maxUsers: number;
  plan: 'light' | 'standard' | 'business' | 'enterprise';
  version: number;
  maxTemplates?: number | null;
  maxUserGroups?: number | null;
  supportReportMessageByTags?: boolean;
  customStatusSupported?: boolean;
  messageReactionSupported?: boolean;
};

export const signInCompanyStripeProductAtom = atom<StripeProduct | null>(null);
export const signInCompanyStripeProductLoadingAtom = atom<boolean>(true);

export const signInCompanyStripeProductEffect = atomEffect((get, set) => {
  const companyLoading = get(companyLoadingAtom);
  if (companyLoading) {
    return;
  }

  const company = get(companyAtom);

  const unsubscribe = onSnapshot(
    doc(db9, 'stripe/collections/products', company.stripeProductId),
    async (snapshot) => {
      if (!snapshot.exists()) {
        set(signInCompanyStripeProductLoadingAtom, false);
        return;
      }
      const data = snapshot.data();

      set(signInCompanyStripeProductAtom, {
        id: snapshot.id,
        name: data.name,
        maxInboxes: data.metadata.maxInboxes || null,
        maxUsers: data.metadata.maxUsers || null,
        plan: data.metadata.plan,
        version: data.metadata.version,
        maxTemplates:
          typeof data.metadata.maxTemplates !== 'undefined'
            ? Number(data.metadata.maxTemplates)
            : null,
        maxUserGroups:
          typeof data.metadata.maxUserGroups !== 'undefined'
            ? Number(data.metadata.maxUserGroups)
            : null,
        supportReportMessageByTags:
          data.metadata.supportReportMessageByTags === 'true',
        customStatusSupported: data.metadata.customStatusSupported === 'true',
        messageReactionSupported:
          data.metadata.messageReactionSupported === 'true',
      });

      set(signInCompanyStripeProductLoadingAtom, false);
    }
  );
  return () => unsubscribe();
});

/**
 * ログインしているcompanyが利用している料金プラン（stripeProduct）を返す
 *
 * @returns [stripeProductData, loading]
 */
export const useSignInCompanyStripeProduct = (): [
  StripeProduct | null,
  boolean,
] => {
  const stripeProduct = useAtomValue(signInCompanyStripeProductAtom);
  const loading = useAtomValue(signInCompanyStripeProductLoadingAtom);
  return [stripeProduct, loading];
};
