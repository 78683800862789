import { estimateServerTimestampsOption } from './common';
import { proxyDataHandler, toRecipients } from '../util';
import firebase from 'firebase/compat/app';

export type InboxType = 'email' | 'google' | 'outlook';

export interface InboxData {
  teamId: string;
  name: string;
  yaritoriName?: string;
  email: string;
  forwardTo: string;
  slackIntegrationChannel?: string;
  chatworkIntegrationRoomId?: number;
  autoCc: string;
  autoBcc: string;
  defaultSignatureId: string | null;
  smtp?: boolean;
  autoReply?: InboxAutoReply;
  type: InboxType;
  tokenId?: string;
  error?: string;
  isOneClickUnsubscribeEnabled?: boolean;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  syncedAt?: firebase.firestore.Timestamp;
}

export type InboxAutoReply = {
  enabled: boolean;
  allDay: boolean;
  startTime: string | null;
  endTime: string | null;
  messageBody: string;
};

export interface Inbox extends InboxData {}

export class Inbox {
  private _id: string;
  private _data: InboxData;
  private _ref: firebase.firestore.DocumentReference<InboxData>;

  constructor(doc: firebase.firestore.DocumentSnapshot<InboxData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for inbox#${doc.id}`);
    }
    this._data = data;
    this._ref = doc.ref;

    return new Proxy(this, proxyDataHandler);
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get teamId() {
    return this._data.teamId;
  }

  get name() {
    return this._data.name || '';
  }

  get yaritoriName() {
    return this._data.yaritoriName || '';
  }

  get emailWithAlias() {
    return this.name.length > 0 ? `${this.name} <${this.email}>` : this.email;
  }

  get email() {
    return this._data.email;
  }

  get forwardTo() {
    return this._data.forwardTo;
  }

  get autoCc() {
    return this._data.autoCc || '';
  }

  get autoCcs() {
    return toRecipients(this.autoCc);
  }

  get autoBcc() {
    return this._data.autoBcc || '';
  }

  get autoBccs() {
    return toRecipients(this.autoBcc);
  }

  get autoRecipients() {
    return [...this.autoCcs, ...this.autoBccs];
  }

  get defaultSignatureId() {
    return this._data.defaultSignatureId || null;
  }

  get smtp() {
    return this._data.smtp === true;
  }

  get createdAt() {
    return this._data.createdAt;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }

  get emailIconName() {
    return this.email.length > 0 ? this.email[0].toUpperCase() : '';
  }

  get isOneClickUnsubscribeEnabled() {
    return this._data.isOneClickUnsubscribeEnabled === true;
  }
}
