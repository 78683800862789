import { Dialog } from '../../../../components/basics/dialog/Dialog';
import { DialogHeader } from '../../../../components/basics/dialog/DialogHeader';
import { Button, Icon } from '../../../../components/basics';
import { Ai } from '../../../../components/icons';
import { DialogContent } from '../../../../components/basics/dialog/DialogContent';
import { Textarea } from '../../../../components/forms/Textarea/Textarea';
import { DialogFooter } from '../../../../components/basics/dialog/DialogFooter';
import { ComponentProps, useState } from 'react';

type Props = Pick<
  ComponentProps<typeof Dialog>,
  'open' | 'onOpenChange' | 'container' | 'modal'
> & {
  onGenerate: (prompt: string) => void;
  placeholder?: string;
};

const DEFAULT_PLACEHOLDER =
  'クレームに対するお詫びメールを100文字程度で作成してください';

export const AiPromptDialog = ({
  onGenerate,
  placeholder,
  ...props
}: Props) => {
  const [prompt, setPrompt] = useState('');
  return (
    <Dialog {...props}>
      <DialogHeader
        title={
          <div className="flex items-center gap-2 text-base font-bold">
            <Icon icon={Ai} size={20} />
            <span>自動生成</span>
          </div>
        }
      />
      <DialogContent className="flex flex-col gap-2">
        <div>自動生成したいメール内容を具体的に指示してください</div>
        <Textarea
          placeholder={placeholder ?? DEFAULT_PLACEHOLDER}
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          autoFocus
          maxRows={5}
          onKeyDown={(e) => {
            if (prompt && e.key === 'Enter' && e.ctrlKey) {
              onGenerate(prompt);
            }
          }}
        />
      </DialogContent>
      <DialogFooter>
        <Button variant="outlined" onClick={() => props.onOpenChange?.(false)}>
          キャンセル
        </Button>
        <Button
          className="border-none bg-[linear-gradient(180deg,_#4E8BE6_0%,_#60CBED_70.31%,_#60DCED_100%)]"
          onClick={() => onGenerate(prompt)}
          disabled={!prompt}
        >
          AI で文章を生成
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
