import React from 'react';
import { Typography } from 'antd';
import { Contact, normalizeStr, onlyUnique } from 'lib';
import reactStringReplace from 'react-string-replace';
import firebase from './firebase';
import _ from 'lodash';
import { Buffer } from 'buffer';

const { Text } = Typography;

export const isProduction = () => process.env.REACT_APP_ENV === 'production';

// ファイルをダウンロードする
export function downloadFromUrl(url, filename) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function (e) {
    if (this.status === 200) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(this.response);
      link.download = filename ?? '';
      link.click();
    }
  };
  xhr.send();
}

export function base64ToArrayBuffer(buffer) {
  const binaryString = window.atob(buffer);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

function downloadByteArray(filename, byte, type) {
  downloadBlob(filename, new Blob([byte], { type }));
}

export function downloadBlob(filename, blob) {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

// 添付ファイルをダウンロードする
export const downloadAttachment = async (attachment) => {
  if (!attachment.storagePath) {
    downloadByteArray(
      attachment.filename ? attachment.filename : 'file',
      base64ToArrayBuffer(
        Buffer.from(attachment.content.data).toString('base64')
      ),
      attachment.contentType
    );
    return;
  }
  const res = await fetchFromPath(attachment.storagePath);
  const blob = await res.blob();
  const filename = attachment.name || attachment.filename || 'file';
  downloadBlob(filename, blob);
};

// 添付ファイルを参照できるURLを生成する
export const fetchFromAttachment = async (attachment) => {
  if (!attachment.storagePath) {
    const dataURI = `data:${attachment.contentType};base64,${Buffer.from(
      attachment.content.data
    ).toString('base64')}`;
    const file = await dataUrlToFile(
      dataURI,
      attachment.filename,
      attachment.contentType
    );
    const url = window.URL.createObjectURL(file);
    const res = { url, ok: true };
    return res;
  }

  const res = await fetchFromPath(attachment.storagePath);
  return res;
};

const getDataUrlForOldAttachment = ({ contentType, type, content }) =>
  `data:${contentType || type};base64,${Buffer.from(content.data).toString(
    'base64'
  )}`;

export const getAttachmentDataUrl = async (attachment) => {
  if (!attachment.storagePath) {
    return getDataUrlForOldAttachment(attachment);
  }
  const blob = await (await fetchFromPath(attachment.storagePath)).blob();
  return window.URL.createObjectURL(blob);
};

export const openOrDownloadAttachment = async (attachment, forceDownload) => {
  const contentType = attachment.contentType || attachment.type;
  const link = document.createElement('a');
  link.href = await getAttachmentDataUrl(attachment);
  if (
    !forceDownload &&
    (contentType === 'application/pdf' || contentType.startsWith('image/'))
  ) {
    link.target = '_blank';
  } else {
    link.download = attachment.name || attachment.filename || 'file';
  }
  link.click();
};

export const toCommentAttachment = (file) => {
  return {
    filename: file.name,
    type: file.type,
    size: file.size,
    storagePath: file.fileName,
  };
};

export async function generateFileFromAttachment(attachment) {
  if (!attachment.storagePath) {
    const dataURI = `data:${attachment.contentType};base64,${Buffer.from(
      attachment.content.data
    ).toString('base64')}`;
    return await dataUrlToFile(
      dataURI,
      attachment.filename,
      attachment.contentType
    );
  }
  const res = await fetchFromPath(attachment.storagePath);
  const blob = await res.blob();
  return new File([blob], attachment.filename, {
    type: attachment.contentType,
  });
}

export async function dataUrlToFile(dataUrl, fileName, type) {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, { type });
}

export const fetchFromPath = async (path) => {
  const ref = firebase.storage().ref(path);
  const url = await ref.getDownloadURL();
  return await fetch(url);
};

export const fetchAsJson = async (path) => {
  const res = await fetchFromPath(path);
  return await res.json();
};

export const fetchAsText = async (path) => {
  const res = await fetchFromPath(path);
  return await res.text();
};

export const isCmdOrCtrl = (e) => {
  return e.metaKey || e.ctrlKey;
};

export const isEnter = (e) => {
  return e.keyCode === 13;
};

export const isCmdOrCtrlEnter = (e) => {
  return isCmdOrCtrl(e) && isEnter(e);
};

export const isBackspaceOrDelete = (e) => {
  return e.keyCode === 8 || e.keyCode === 46;
};

// objectの配列からidが重複しているものを取り除く
export const deduplicateById = (arr) => [
  ...new Map(arr.map((item) => [item.id, item])).values(),
];

// Highlight matched part of a string.
export const highlight = (text, highlightValue) => {
  if (!text || !highlightValue) {
    return text;
  }
  const normalizedText = normalizeStr(text);
  const normalizedHighlight = normalizeStr(highlightValue);
  const index = normalizedText.indexOf(normalizedHighlight);
  if (index === -1) {
    return text;
  }
  const end = index + highlightValue.length;
  return (
    <>
      {text.slice(0, index)}
      <Text mark>{text.substring(index, end)}</Text>
      {text.slice(end)}
    </>
  );
};

// 拡張子を抽出する
// 例) test.pdf => pdf
export const extractFileExtension = (filename) => {
  const values = filename.split('.');
  if (values.length <= 1) return '';
  return values.pop();
};

// storage用のファイル名を作成する
// 例) test.pdf => attachment.pdf, test.jpg => attachment.jpg, test => attachment
export const generateStorageAttachmentName = (filename) => {
  const extension = extractFileExtension(filename);
  if (!extension) return 'attachment';
  return 'attachment.' + extension;
};

// メンションされたユーザを抽出する
export const extractMentionedUsers = (comment, users, groups, teamMembers) => {
  let mentionComment = comment;
  const sortedUsers = _.orderBy(users, [(u) => u.name.length], ['desc']);
  const sortedGroups = _.orderBy(groups, [(g) => g.name.length], ['desc']);
  return [
    // 個別にメンションされたユーザ
    ...sortedUsers
      .filter((u) => {
        if (mentionComment.includes('@' + u.name)) {
          mentionComment = mentionComment.replace(`@${u.name}`, '');
          return true;
        }
        return false;
      })
      .map((u) => u.id),
    // メンションされたグループ
    ...sortedGroups
      .filter((g) => {
        if (mentionComment.includes('@' + g.name)) {
          mentionComment = mentionComment.replace(`@${g.name}`, '');
          return true;
        }
        return false;
      })
      .flatMap((g) =>
        teamMembers.map((u) => u.id).filter((uid) => g.isMember(uid))
      ),
    // @allが含まれる場合、チームメンバー全員
    ...(mentionComment.includes('@all') ? teamMembers.map((u) => u.id) : []),
  ].filter(onlyUnique);
};

// 正規表現をエスケープする
function escapeRegExp(text) {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

// メンションをハイライトする
export const highlightMention = (text, users, groups) => {
  const mentions = [
    '@all',
    ...users.map((u) => '@' + escapeRegExp(u.name)),
    ...groups.map((g) => '@' + escapeRegExp(g.name)),
  ].join('|');
  const regex = new RegExp(`(${mentions})`, 'g');
  // TODO: keyにindexを使用しない
  return reactStringReplace(text, regex, (match, i) => (
    <span className="font-bold text-sea-500" key={i}>
      {match}
    </span>
  ));
};

// TODO: contactが存在しないが、contactとしてfromを擬似的に作成するため、あまり良くないので修正したい
export const createContact = (email, teamId) => {
  const addressContact = new Contact();
  addressContact._data = {
    name: email.name,
    email: email.address,
    teamId: teamId,
  };
  return addressContact;
};

export const getTagLabel = (tagLabel, options = {}) => {
  const parentTagLabel = options.parentTagLabel;
  const tagMaxLength = options.tagMaxLength ?? 15;
  const parentTagMaxLength = options.parentTagMaxLength ?? 5;

  if (parentTagLabel) {
    const label = `${parentTagLabel}/${tagLabel}`;
    const isLongTag = label.length > tagMaxLength;
    const isLongParentTag = parentTagLabel.length > parentTagMaxLength;
    if (isLongTag && isLongParentTag) {
      const shortParentTagLabel = `${parentTagLabel.slice(
        0,
        parentTagMaxLength
      )}...`;
      const _isLongTag =
        `${shortParentTagLabel}/${tagLabel}`.length > tagMaxLength;
      const shortLabel = _isLongTag
        ? `${`${shortParentTagLabel}/${tagLabel}`.slice(0, tagMaxLength)}...`
        : `${shortParentTagLabel}/${tagLabel}`;
      return { shortLabel, label };
    }
    if (isLongTag && !isLongParentTag) {
      const shortLabel = `${`${parentTagLabel}/${tagLabel}`.slice(
        0,
        tagMaxLength
      )}...`;
      return { shortLabel, label };
    }
    return { label };
  }

  const isLongTag = tagLabel.length > tagMaxLength;
  if (isLongTag) {
    const shortLabel = `${tagLabel.slice(0, tagMaxLength)}...`;
    return { shortLabel, label: tagLabel };
  }

  return { label: tagLabel };
};

export const getOAuthRedirectUri = (service) => {
  return document.location.origin + `/oauth/${service}/callback`;
};
