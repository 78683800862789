/* eslint-disable @typescript-eslint/no-redeclare */
import { MessageData } from 'lib';
import firebase from '../../firebase';

export type FetchMessagesOptions = {
  teamId?: string;
  inboxId?: string;
  tagId?: string;
  assignedMe?: boolean;
  startAfter?: firebase.firestore.DocumentSnapshot<MessageData>;
};

export const MessageView = {
  Unprocessed: 'messages',
  Processed: 'processed',
  Sent: 'sent',
  Deleted: 'deleted',
  All: 'all',
} as const;

export type MessageView = (typeof MessageView)[keyof typeof MessageView];
