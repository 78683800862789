import { atom, useAtomValue } from 'jotai';
import { joinedTeamIdsAtom } from './team';
import { MessageLike, messageLikeConverter, MessageStatus } from 'lib';
import {
  atomFamilyWithLRU,
  atomWithPaginate,
  LoadingPaginate,
  Paginate,
  PaginateAtom,
} from '../../utils/atom';
import { companyCollection, registerUnsubscribe } from '../../firestore';
import { onSnapshot, Query, query, Timestamp, where } from 'firebase/firestore';
import { store } from '../../providers/StoreProvider';
import mobxStore from '../../store';
import { meAtom, threadViewAtom } from '../auth';
import { isEqual } from 'lodash';
import { mentionedMessageMapAtom } from './mention';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { tabCountsEffect } from './count/tab';
import { isAllStatusesLoadedAtom } from './customStatuses';
import { FrontMessageStatus } from 'utils/frontMessageStatus';

type MessagesParams = {
  teamId?: string;
  inboxId?: string;
  tagId?: string;
  status?: FrontMessageStatus;
  notProcessed?: boolean;
  assignedMe?: boolean;
  deleted?: boolean;
};

type MessagesFilter = {
  teamIds: string[];
  inboxId?: string;
  tagId?: string;
  status?: FrontMessageStatus;
  notProcessed?: boolean;
  assignee?: string;
  deleted: boolean;
};

const writableMessagesFilterAtom = atom<MessagesFilter>({
  teamIds: [],
  deleted: false,
});

export const messagesFilterAtom = atom<MessagesFilter, [MessagesParams], void>(
  (get) => get(writableMessagesFilterAtom),
  (get, set, params) => {
    const teamIds = params.teamId ? [params.teamId] : get(joinedTeamIdsAtom);
    set(writableMessagesFilterAtom, {
      teamIds: teamIds,
      inboxId: params.inboxId,
      tagId: params.tagId,
      status: params.status,
      notProcessed: params.notProcessed,
      assignee: params.assignedMe ? get(meAtom).id : undefined,
      deleted: params.deleted ?? false,
    });
  }
);

const queryWhereStatus = <T>(
  q: Query<T>,
  status: FrontMessageStatus
): Query<T> => {
  const filterStatus =
    status.statusType === MessageStatus.CustomStatus
      ? status.customStatusId
      : status.statusType;
  return query(q, where('status', '==', filterStatus));
};

const messagesFamily = atomFamilyWithLRU<
  MessagesFilter,
  Paginate<MessageLike>,
  PaginateAtom<MessageLike>
>({
  initializeAtom: (filter) => {
    const threadView = store.get(threadViewAtom);

    let q = query(
      companyCollection(
        threadView ? 'threads' : 'messages',
        messageLikeConverter
      ),
      where('teamId', 'in', filter.teamIds)
    );

    if (threadView && filter.deleted) {
      q = query(q, where('hasDeleted', '==', filter.deleted));
    } else {
      q = query(q, where('deleted', '==', filter.deleted));
    }
    if (filter.inboxId) {
      q = query(q, where('inboxId', '==', filter.inboxId));
    }
    if (filter.tagId && !filter.deleted) {
      const tag = mobxStore.getTag(filter.tagId);
      const allTab = !filter.status;
      if (!tag?.isDefault || !allTab) {
        q = query(q, where('tags', 'array-contains', filter.tagId));
      }
    }
    if (filter.assignee) {
      q = query(q, where('assignee', '==', filter.assignee));
    }

    if (filter.status) {
      q = queryWhereStatus(q, filter.status);
    } else if (filter.notProcessed) {
      q = query(q, where('status', '!=', MessageStatus.Processed));
    }

    return atomWithPaginate<MessageLike>(q, (snapshot, prev) => {
      snapshot.docChanges().forEach((change) => {
        let message = change.doc.data();
        if (threadView && filter.deleted) {
          message = message.toDeletedThread();
        }
        switch (change.type) {
          case 'added':
            prev.push(message);
            break;
          case 'modified':
            prev.splice(
              prev.findIndex((m) => m.id === message.id),
              1,
              message
            );
            break;
          case 'removed':
            prev.splice(
              prev.findIndex((m) => m.id === message.id),
              1
            );
            break;
        }
      });

      return prev.sort((a, b) => b.date.diff(a.date));
    });
  },
  max: 20,
  maxSize: 200,
  sizeCalculator: (value) => {
    if (value.state !== 'hasData') {
      return 0;
    }
    return value.data.length;
  },
  dispose: (anAtom) => store.set(anAtom, 'unsubscribe'),
  areEqual: isEqual,
});

export const messagesAtom: PaginateAtom<MessageLike> = atom(
  (get) => {
    const filter = get(messagesFilterAtom);
    const allStatusesLoaded = get(isAllStatusesLoadedAtom);
    if (!filter.teamIds.length || !allStatusesLoaded) {
      return LoadingPaginate as Paginate<MessageLike>;
    }
    return get(messagesFamily(filter));
  },
  async (get, set) => {
    await set(messagesFamily(get(messagesFilterAtom)), 'loadMore');
  }
);

const updateKey = 'onUpdateMessage';

export const useOnUpdateMessage = () => {
  const threadView = useAtomValue(threadViewAtom);
  const teamIds = useAtomValue(joinedTeamIdsAtom);
  useAtomValue(tabCountsEffect);
  const match = useRouteMatch<{ tray: string }>('/search/:tray/:query');
  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        companyCollection(
          threadView ? 'threads' : 'messages',
          messageLikeConverter
        ),
        where('teamId', 'in', teamIds),
        where('updatedAt', '>', Timestamp.now())
      ),
      async (snapshot) => {
        await Promise.all(
          snapshot.docChanges().map(async (change) => {
            const data = change.doc.data();
            const searchStore = mobxStore.searchStore;
            if (searchStore.inSearch) {
              searchStore.onMessageChange(
                data,
                change.type,
                match?.params.tray === 'deleted'
              );
            }

            const messageMap = store.get(mentionedMessageMapAtom);
            if (data.id in messageMap) {
              store.set(mentionedMessageMapAtom, (prev) => ({
                ...prev,
                [data.id]: data,
              }));
            }
          })
        );
      }
    );
    registerUnsubscribe(updateKey, unsubscribe);
  }, [threadView, teamIds]);
};
