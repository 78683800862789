import { DocumentReference, onSnapshot, Query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { registerUnsubscribe } from '../../firestore';
import { v4 } from 'uuid';

export const useSubscribeDocument = <T>(
  ref: DocumentReference<T> | undefined
): T | undefined => {
  const [data, setData] = useState<T>();
  useEffect(() => {
    if (ref) {
      return registerUnsubscribe(
        v4(),
        onSnapshot(ref, (snapshot) => setData(snapshot.data()), console.error)
      );
    } else {
      setData(undefined);
    }
  }, [ref]);
  return data;
};

export const useSubscribeCollection = <T>(query: Query<T> | undefined): T[] => {
  const [data, setData] = useState<T[]>([]);
  useEffect(() => {
    if (query) {
      return registerUnsubscribe(
        v4(),
        onSnapshot(
          query,
          (snapshot) => setData(snapshot.docs.map((doc) => doc.data())),
          console.error
        )
      );
    } else {
      setData([]);
    }
  }, [query]);
  return data;
};
