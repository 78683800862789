import { Button, Icon, Spin } from 'antd';
import { Chat as ChatEntity, chatHistoriesDelimiter } from 'lib';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { compose } from 'recompose';
import styled from 'styled-components';
import media from 'styled-media-query';
import { v4 as uuidv4 } from 'uuid';
import { eventNames, logEvent } from '../../../analytics';
import * as color from '../../../color';
import firebase, { db } from 'firebase.js';
import { H2 } from '../../Common/H2';
import { SPOnly } from '../../Common/MediaQuery';
import { AddComment } from '../Conversations/Conversation/AddComment';
import Chats from './chats';
import EditMemberModal from './editMemberModal';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chats: [],
      chatsLoading: true,
      openMemberModal: false,
    };
  }

  componentDidMount() {
    this.fetchAndSync();
  }

  componentWillUnmount() {
    if (this._unsubscribeChats) this._unsubscribeChats();
  }

  fetchAndSync = async () => {
    const { room } = this.props;
    const query = room.ref.collection(`chats`).orderBy('createdAt');
    this._unsubscribeChats = query.onSnapshot(
      async (snapshot) => {
        let chats = [];
        snapshot.forEach((doc) => chats.push(new ChatEntity(doc)));
        this.setState({ chats, chatsLoading: false });
        // 既読にする
        this.markAsRead();
      },
      (err) => {
        console.error('Room.fetchAndSync:', err);
      }
    );
  };

  // 既読にする
  markAsRead = async () => {
    const { room } = this.props;
    await room.ref.update({
      [`readers.${this.props.store.me.id}`]:
        firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  addComment = async (comment) => {
    const { room } = this.props;

    const addChat = {
      type: 'comment',
      text: comment,
      creator: this.props.store.me.id,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // 参照を作成する
    const chatRef = this.props.room.ref.collection('chats').doc();

    // トランザクション内で実行する
    await db.runTransaction(async (tx) => {
      // コメントを作成する
      await tx.set(chatRef, addChat);
      // roomのchatHistoriesと更新日時を更新する
      await tx.update(room.ref, {
        [`chatHistories.${
          this.props.store.me.id
        }${chatHistoriesDelimiter}${uuidv4()}`]:
          firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    });

    // 既読にする
    await this.markAsRead();
    logEvent(eventNames.add_chat);
    setTimeout(this.scrollToBottom, 200);
  };

  scrollToBottom = () => {
    scroll.scrollToBottom({
      containerId: 'ConversationContainer',
      duration: 0,
    });
  };

  openEditMember = () => {
    console.log('this.props.room.isGroup', this.props.room.isGroup);
    if (this.props.room.isGroup) {
      this.setState({
        openMemberModal: true,
      });
    }
  };

  render() {
    const { room } = this.props;
    const { chats, chatsLoading } = this.state;

    const membersExceptMe = room.members.filter(
      (m) => this.props.store.me.id !== m
    );

    const title = membersExceptMe
      .map((m) => this.props.store.getUser(m)?.name || '削除されたユーザ')
      .join(', ');

    return (
      <Wrapper id="ConversationContainer">
        {/* タイトル */}
        <Header>
          <SPOnly>
            <Button
              type="primary"
              shape="circle"
              icon="menu"
              onClick={this.props.store.openDrawer}
              style={{ marginRight: 10 }}
            />
          </SPOnly>
          <Icon type="message" style={{ fontSize: '16px', marginRight: 4 }} />
          <HH2
            onClick={this.openEditMember.bind(this)}
            style={{ margin: 0, cursor: 'pointer' }}
            isGroup={room.isGroup}
          >
            {title}
            {room.isGroup && <HIcon type="down" />}
          </HH2>
        </Header>

        <Conversation>
          {chatsLoading && (
            <SpinWrapper>
              <Spin indicator={<Icon type="loading" spin />} />
            </SpinWrapper>
          )}
          <Chats chats={chats} scrollToBottom={this.scrollToBottom} />
        </Conversation>

        <AddComment
          me={this.props.store.me}
          addComment={this.addComment}
          teamMembers={[]}
          users={[]}
          groups={[]}
          placeholder="メッセージ"
          disabled={this.props.store.me.isReadOnly}
        />

        <EditMemberModal
          open={this.state.openMemberModal}
          setOpen={(status) => this.setState({ openMemberModal: status })}
          room={room}
        ></EditMemberModal>
      </Wrapper>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);

export const HIcon = styled(Icon)`
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
`;

export const HH2 = styled(H2)`
  border-radius: 5px;
  line-height: 18px;
  padding: 5px 5px;
  ${({ isGroup }) => `
    :hover{
      background: ${isGroup ? '#f2f2f2' : 'unset'};
    }
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: auto;
  flex: 1;
  background-color: ${color.common.white};

  ${media.lessThan('small')`
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 2;
  `};
`;

const Header = styled.div`
  height: 60px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-bottom: solid 0.5px #f1f1f3;
`;

const Conversation = styled.div`
  flex: 1;
`;

const SpinWrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
