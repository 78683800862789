import { observer } from 'mobx-react';
import styled from 'styled-components';
import React, { useState } from 'react';
import { H2 } from '../../Common/H2';
import { DefaultButton } from '../../Common/Button';
import { GroupModal } from './Group/GroupModal';
import { useStore } from '../../../hooks/useStore';
import { Group } from './Group';
import { Group as GroupEntity } from '../../../store/entity';
import Tooltip from '../../Common/Tooltip';
import { isPermitAddUserGroup } from 'atoms/firestore/companyPermission';
import { companyAtom } from 'atoms/auth';
import { useAtomValue } from 'jotai';

export const Groups = observer(() => {
  const [modalGroup, setModalGroup] = useState<GroupEntity>();
  const [modalVisible, setModalVisible] = useState(false);
  const company = useAtomValue(companyAtom);

  const { me, groupStore } = useStore();
  const { groups } = groupStore;
  const sortedGroups = groups
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name, 'ja'));

  const isAdmin = me.isAdmin;

  const openModal = (group?: GroupEntity) => {
    setModalGroup(group);
    setModalVisible(true);
  };

  const isPermitted = isPermitAddUserGroup(company, groups.length);

  let tooltipTitle = null;
  if (!isAdmin) {
    tooltipTitle = '権限がありません';
  } else if (!isPermitted) {
    tooltipTitle = '上限に達したため、プランをアップグレードしてください。';
  }

  return (
    <Wrapper>
      <Top>
        <H2>グループ一覧</H2>
        <Tooltip title={tooltipTitle} visible={tooltipTitle}>
          <div className="mb-1 ml-[200px]">
            <CreateButton
              type="primary"
              onClick={() => openModal()}
              disabled={!isAdmin || !isPermitted}
            >
              グループを作成する
            </CreateButton>
          </div>
        </Tooltip>
      </Top>
      {sortedGroups.length ? (
        sortedGroups.map((group) => (
          <Group key={group.id} group={group} onEdit={openModal} />
        ))
      ) : (
        <EmptyMessage>まだグループが作成されていません</EmptyMessage>
      )}
      <GroupModal
        group={modalGroup}
        visible={modalVisible}
        close={() => setModalVisible(false)}
      />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding-top: 32px;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
`;

const CreateButton = styled(DefaultButton)`
  height: 40px;
  padding: 0 30px;
`;

const EmptyMessage = styled.div`
  margin-top: 16px;
`;
