import { PageWithMainNav } from '../../../../App/Common/MainNav';
import { DeliveryMessagesPageWithLogic } from '../../list/DeliveryMessagesPage/DeliveryMessagesPageWithLogic';
import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';
import { DeliveryMessageDetailPage } from '../DeliveryMessageDetailPage/DeliveryMessageDetailPage';
import { useAtomValue } from 'jotai/index';
import { companyAtom } from '../../../../atoms/auth';

export const DeliveryPage = () => {
  const { isDeliveryEnabled } = useAtomValue(companyAtom);
  if (!isDeliveryEnabled) {
    return <Redirect to="/me/drafts" />;
  }

  return (
    <>
      <PageWithMainNav>
        <div className="px-0 lg:px-4">
          <div className="w-[1200px] max-w-full">
            <DeliveryMessagesPageWithLogic />
          </div>
        </div>
      </PageWithMainNav>
      <Switch>
        <Route
          exact
          path="/delivery/new"
          render={() => <DeliveryMessageDetailPage messageId={undefined} />}
        />
        <Route
          exact
          path="/delivery/:messageId"
          render={({ match }) => (
            <DeliveryMessageDetailPage messageId={match.params.messageId} />
          )}
        />
      </Switch>
    </>
  );
};
