import cheerio from 'cheerio';

const dispositionInline = 'inline';

/**
 * Message entity のattachments が MessageAttachment のタイプ、こちらのタイプをMessageAttachmentに統一し、次のAttachmentタイプを消すべき。
 */
type Attachment = {
  disposition: string;
  contentDisposition: string;
  contentType: string;
  content_id: string;
  uid: string;
};

/**
 * Sent entity のattachmentsのタイプがanyのため、暫定的に残す。
 */
type SentAttachment = {
  disposition: string;
  contentDisposition: string;
  type: string;
  content_id: string;
  uid: string;
  storagePath: string;
  size: number;
};

const extractInlineAttachments = (attachments: Attachment[]) =>
  attachments.filter(
    (a) => (a.disposition || a.contentDisposition) === dispositionInline
  );

export const extractNonInlineAttachments = (attachments: Attachment[]) =>
  attachments.filter(
    (a) =>
      (a.disposition || a.contentDisposition) !== dispositionInline ||
      !a.contentType?.startsWith('image/')
  );

export const extractNonInlineSentAttachments = (
  attachments: SentAttachment[]
) =>
  attachments.filter(
    (a) =>
      (a.disposition || a.contentDisposition) !== dispositionInline ||
      !a.type?.startsWith('image/')
  );

const extractUsedContentIds = (bodyHtml: string) => {
  const $ = cheerio.load(bodyHtml);
  const usedContentIds: string[] = [];
  $('img[data-content_id]').each((i, elem) => {
    // FIXME
    usedContentIds.push($(elem).attr('data-content_id') as string);
  });
  return usedContentIds;
};

const extractUnusedInlineAttachments = (
  bodyHtml: string,
  attachments: Attachment[]
) => {
  const usedContentIds = extractUsedContentIds(bodyHtml);
  const inlineAttachments = extractInlineAttachments(attachments);
  return inlineAttachments.filter(
    (a) => !usedContentIds.includes(a.content_id)
  );
};

export const extractUsedAttachments = (
  bodyHtml: string,
  attachments: Attachment[]
) => {
  const unusedAttachmentUids = extractUnusedInlineAttachments(
    bodyHtml,
    attachments
  ).map((a) => a.uid);
  return attachments.filter((a) => !unusedAttachmentUids.includes(a.uid));
};
