import { MessageLikeData } from './message';
import { createConverter, createEntityFactory } from '../converter';
import { FirestoreDataConverter } from 'firebase/firestore';
import moment from 'moment';

export interface ThreadData extends MessageLikeData {
  messages: string[];
  sent: string[];
  hasDeleted: boolean;
  deletedThread?: ThreadData;
}

export const createThread = createEntityFactory(
  'Thread',
  (data: ThreadData, ref) => ({
    date: moment(data.date?.toMillis() ?? data.createdAt.toMillis()),

    get tags() {
      return data.tags || [];
    },

    toDeletedThread: () =>
      createThread(ref, {
        ...(data.deletedThread as ThreadData),
        teamId: data.teamId,
        inboxId: data.inboxId,
        deleted: true,
      }),
  })
);

export type Thread = ReturnType<typeof createThread>;

export const threadConverter: FirestoreDataConverter<Thread> =
  createConverter(createThread);
